/**************** MAIN STYLE CONSTANTS AND ELEMENTS **************/
$yellow: #FFC300;
$lightblue: #1CB0F6;
$skyblue: #45D3DC;
$blue: #1273A3;
$darkblue: #015587;
$newDarkBlue: #1D5F7F;
$yellowtransparent: #FFC30070;
$purpletransparent: #CA94FF80;
$bluehighlight: #BCE9FF;
$palered: rgba(229, 59, 59, 0.2);
$palegreen: rgba(176, 233, 77, 0.4);
$gray: #DFDFDF;
$limeGreen: #8ED416;
$darkGreen: #365500;
$teal: #0BB898;
$correctGreen: #7AC70C;
$correctGreenDarkened: #63A308;;
$correctBar: #C8E596;
$incorrectBar: #E99895;
$incorrectRed: #D33131;
$incorrectRedDarkened: #8D0101;
$lightGold: #FFECCB;
$orangeGold: #FF9E01;
$peach: #EDB875;
$offwhite: #FFFBF6;
$StorePeach: #FBEADA;
$StoreLowerBackground:  #46576F;
$StoreGray: #C4C4C4;
$StoreLockedRed: #D33131;
$StoreUnlockedGreen: #5E9B38;

/** in-class game colors **/
$red: #BF1919;
$gold:#F6C544;
$calmBlue: #2C97D3;
$brightPurple: #A560EB;
$newGray: #DDDDDD;


$roboto: 'Roboto', sans-serif; 
$oleo: 'Oleo Script', cursive;
$inter: 'Inter', sans-serif;
$baloo: 'Baloo Thambi 2', cursive;
$josefin: 'Josefin Sans', sans-serif;
$calistoga: 'Calistoga', cursive;

ul {
    list-style: none;
}


html, body{
    box-sizing: border-box;
    font-family: $josefin;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
}

body {
    min-height: 100vh;
}

.loading{
    background-image: url('../images/LOADING.svg');
    min-height: 100vh;
    background-position: cover;
}

#main{
    background-color: white;
    min-height: 100vh;
}

:focus{
    outline: none !important;
}
button:hover{
    cursor: pointer;
}

button:active, button:focus{
    border: none;
}

#main{
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
}

a:active{
    text-decoration: none;
    color: white;
}

a{
    text-decoration: none;
}


/******************** BETA TEST PAGE **************/
.main-background{
    display: flex;
    flex-direction: row;
    background-color: #015587;
    width: 100vw;
    height: 100vh;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

#beta-main-background{
    background-image: url('../images/betaPage.svg');
    background-size: cover;
    background-position: center;
}

.main-left{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.main-left-text{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.main-left-title{
font-family: $oleo;
font-style: normal;
font-weight: bold;
font-size: 85px;
line-height: 118px;
/* identical to box height */
text-align: left;
letter-spacing: 0.03em;

color: #FFC300;
}

.main-left-subtitle{
    font-family: $josefin;
font-style: normal;
font-weight: 600;
font-size: 43px;
line-height: 43px;
/* identical to box height */

letter-spacing: 0.03em;

color: #FFFFFF;
}

.main-left-mini-subtitle{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
width: 46.67vw;

color: #FFFFFF;
}

.main-left-input-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 61px;
    
}

.main-right{
    width: 43.40vw;
    right: 0px;
}

.access-code{
    width: 100%;
}

.access-code-prompt {
    position: relative;
    display: flex;
    color: #e4e4e4;
    align-items: flex-start;
    margin-top: 50px;
    width: 100%;
    justify-content: center;
}

.access-code-prompt-modal {
    color: #015587;
    font-size: 50px;
}

.access-code-click-here {
    color: $yellow;
    cursor: pointer;
    font-style: italic;
    margin-left: 10px;
}

.input-information {
    display: flex;
    align-items: center;
    flex-direction: column;
}

#enter-site-passcode-input {
    margin-top: 70px;
    width: 400px;
    height: 75px;
    font-size: 30px;
    text-align: center;
}

.enter-site-button {
    font-size: 27px;
    margin-top: 30px;
    background: $yellow;
    padding: 15px;
    cursor: pointer;
}

.main-left-beta-input{
    width: 442px;
height: 53px;

font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 18px;
display: flex;
margin-right: 26px;
align-items: center;
letter-spacing: 0.03em;

color: #8D8D8D;
background: #E4E4E4;
border-radius: 12px;
border: none;
}

.main-left-beta-input::-moz-placeholder{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 18px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
padding-left: 10px;
color: #8D8D8D;
}

.main-left-beta-input:-ms-input-placeholder{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 18px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
padding-left: 10px;
color: #8D8D8D;
}

.main-left-beta-input::placeholder{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 18px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
padding-left: 10px;
color: #8D8D8D;
}

.main-left-join-us{
    font-family: $josefin;
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 24px;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
width: 166px;
height: 48px;

background: #FFC300;
border: none;
color: #015587;
width: 168px;
height: 56px;

text-transform: uppercase;

box-shadow: 0px 9px 0px #FF9400;
border-radius: 19px;
}

.modal{
    width: 924px;
    height: 567px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 47px;
    border: none;
    outline: none;
}

.modal:focus, .modal:active{
    border: none;
    outline: none !important;
}

.modal-top{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

#black-x{
    margin-right: 2vw;
    margin-top: 2vw;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 100;
}

.ReactModal__Overlay, .ReactModal__Overlay--after-open{
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.overlay{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#black-x:hover{
    cursor: pointer;
}

.modal-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    position: relative;
    z-index: 4;
}

.subtitle-modal, .check-out{
    font-family: $josefin;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    
    color: #373737;
}

.social-modal{
    color: $yellow;
    font-family: $josefin;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-top: 4vh;
}

.title-modal{
    font-family: $oleo;
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 89px;
    letter-spacing: 0.03em;
    
    color: #015587;
}

.confetti{
    position: fixed;
    z-index: 2;
}

.modal-image{
    margin-right: 80px;
}

.social-medias-modal{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.twitter-modal, .instagram-modal{
    margin-right: 1.23vw;
}

.modal-close{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #CFCFCF;
}

.modal-close:hover{
    cursor: pointer;
}

@media only screen and (max-width: 900px){

    .main-background{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
    }

    .main-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-left-input-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .main-left-title, .main-left-text, .main-left-subtitle, .main-left-mini-subtitle{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        margin-right: 20px;
        width: 300px;
    }

    .main-left-title, .main-left-subtitle, .main-left-mini-subtitle{
        margin-bottom: 40px;
    }

    .main-left-beta-input{
        margin-left: 20px;
        margin-right: 20px;
        width: 90vw;
        margin-top: 0px;
        margin-bottom: 40px;
    }

    .main-left-beta-input::-moz-placeholder{
        text-align: center;
    }

    .main-left-beta-input:-ms-input-placeholder{
        text-align: center;
    }

    .main-left-beta-input::placeholder{
        text-align: center;
    }

    .main-right{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .modal{
        width: 90vw;
    height: 50vh;
    margin-left: 5vw;
    margin-top: 5vh;
    }

    .title-modal{
        text-align: center;
        font-size: 34px;
    }

    .modal-content{
        margin-top: 5vh;
    }

}




/************* NAVBAR **************/
.navbar-holder{
    width: 100%;
    height: 215px;
    background: #8ED416;
    border-radius: 43px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: -72px;
    left: 0px;
    z-index: 100;
    }
    
    #nav-logo{
        margin-top: 72px;
        font-family: $oleo;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 83px;
        /* identical to box height */
        letter-spacing: 0.03em;
        color: #FFFFFF;
    }
    
    .nav-text{
        margin-top: 72px;
        font-family: $roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 37px;
        line-height: 43px;
        letter-spacing: 0.03em;
        margin-left: 60px;
        color: #FFFFFF;
    }
    
    .link-holder{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 70vw;
    }

/*********** PROFILE PAGE **********/
.profile-right-top{
    width: 738px;
    height: 275px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 50px;
    border: none;
    outline: none;
    margin-bottom: 10%;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1), -4px -4px 20px rgba(0, 0, 0, 0.1);
}
.profile-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3vh;
}
.profile-name{
    font-family: $josefin;
    font-size: 60px;
    color: $limeGreen;
    font-weight: bold;
    margin-bottom: 30px;
}
.profile-xp{
    font-family: $josefin;
    font-size: 30px;
    color: #114353;
    font-weight: bold;
}
.profile-levels{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
}

.level-icon{
    margin-bottom: 10px;
}

.profile-level{
    margin-bottom: 5px;
}

.profile-level, .profile-levelnum{
    display: flex;
    flex-direction: row;
    font-family: $josefin;
    font-size: 27px;
    color: #1CB100;
    font-weight: bold;
    font-style: italic;
}
.profile-right-bottom{
    width: 729px;
    height: 289px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: #FFFFFF;
    border-radius: 50px;
    border: none;
    outline: none;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1), -4px -4px 20px rgba(0, 0, 0, 0.1);
}
.profile-right-top-right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.profile-stats{
    font-family: $josefin;
    font-size: 50px;
    color: #46576F;
    font-weight: bold;
    display: flex;
    align-self: flex-start;
}
.profile-right-bottom-section{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
}
.profile-stats-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 25%;
}
.profile-stats-title{
    font-family: $josefin;
    font-size: 20px;
    color: #373737;
    font-weight: bold;
    height: 25%;
    margin-bottom: 4%;
}
.profile-stats-subtitle{
    font-family: $josefin;
    font-size: 16px;
    text-align: center;
    color: #AFAFAF;
    height: 65%;
}
.profile-stats-streak, .profile-stats-badges, .profile-stats-infinity{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 25%;
    justify-content: flex-end;
    align-content: center;
}

.stats-image{
    height: 150px;
    display: flex;
    align-self: center;
    margin-bottom: 28px;
}

.stats-image-2{
    display: flex;
    height: 200px;
    align-self: center;
}

/*********** PROFILE PAGE NAV BAR **********/
.navbar-holder-profile{
    width: 100%;
    height: 100%;
    min-height: 15vh;
    background: #015587;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.profile-nav-left{
    width: 35vw;  
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
}


/************* AUTH NAV BAR ***************/
.navbar-holder-auth{
    width: 100%;
    height: 10vh;
    background: #015587;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}
    
#nav-logo-auth{
    font-family: $oleo;
    font-style: normal;
    font-weight: bold;
    line-height: 10vh;
    /* identical to box height */
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.auth-nav-left{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;  
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
}

#tall-line{
    font-size: 3em;
    font-weight: thin;
    margin: 0 3px;
}
.auth-nav-text-line{
    font-size: 1.4em;
    color: white;
}

.auth-nav-text {
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.auth-nav-right{
    width: 30vw;
    font-size: 1.2em;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 2vw;
    align-items: center;
    img {
        height: 8vh;
    }
}

.go-back{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.nav-flex-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.auth-nav-right-left{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.anrli-pic{
    margin-right: 16px;
    margin-left: 16px;
}



.auth-nav-right-left-item{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 30px;
/* identical to box height */

letter-spacing: 0.03em;

color: #FFFFFF;
}
.auth-nav-right-left-item-name{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
}
.name-title{
    margin-right: 10px;
}
.auth-nav-right-left:hover{
    cursor: pointer;
}
.auth-dropdown{
    position: absolute;
    right: 3vw;
    top: 9vh;
    width: 22vw;
    height: 0px;
    opacity: 0;
    background: #015587;
border-bottom: 3px solid #FFFFFF;
border-left: 3px solid white;
border-right: 3px solid white;
box-sizing: border-box;
border-radius: 12px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
transition: 150ms ease-out all;
}
.auth-dropdown:hover{
    cursor: pointer;
}
.log-out-button{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 30px;
text-align: center;
letter-spacing: 0.03em;
color: #FFFFFF;
}

/************************* Head to Head tile ****************/
#head-to-head-tile {
    background-color: $orangeGold;
    ;
}


/************************* Head to Head ****************/
.headToHead {
    width: 100vw;
    min-height: 100vh;
    background: #BABABA; /* change this */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.head-subtitle {
    margin-top: 40px;
    margin-left: 220px;
    font-size: 23px;
}

.head-rules {
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 20px;
    letter-spacing: 0.03em;
    height: 80%;
    color: #000000;
    margin-top: 15px;
    margin-left: 59px;
}

.game-info-flex {
    background-color: #FFFFFF;
    width: 92.84vw;
    min-height: 80vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 3.41vh;
    margin-bottom: 3.41vh;
    font-size: 23px;
}

.head-activity-type-word-1 {
    width: 30px;
    height: 28px;
    color: white;
    background: #61CCFF;
    border-radius: 5px;
    margin-right: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.head-activity-type-word-2 {
    width: 30px;
    height: 28px;
    color: white;
    background: #13ABF2;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.head-activity-type-word-3 {
    width: 30px;
    height: 28px;
    color: white;
    background:  #1F82B1;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.head-activity-type-word-4 {
    width: 30px;
    height: 28px;
    color: white;
    background: #054F72;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.head-activity-type-word-5 {
    width: 30px;
    height: 28px;
    color: white;
    background: #023046;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.head-title {
    margin-top: 5vh;
    margin-bottom: 22px;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 60px;
    display: flex;
    align-items: flex-start;
    letter-spacing: 0.03em;
    margin-left: 59px;
    color: #D33131;
}

.head-activity-types {
    margin-left: 200px;
}

.head-activity {
    display: flex;
    flex-direction: row;
    padding-left: 35px;
    margin-top: 10px;
    margin-bottom: 6px;
    border-radius: 5px;
    margin-left: 59px;
}

.head-activity-type-word {
    color: #494848;
    line-height: 23px;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.03em;
    height: 80%;
    padding-top: 4px;
}

.head-play-holder {
    align-self: flex-end;
    padding-bottom: 44px;
    padding-right: 44px;
    position: absolute;
    right: 3.45vw;
    bottom: 0vh;
}

.worm {
    position: absolute;
    width: 390.12px;
    height: 280.14px;
    left: 75%;
    top: 70px;
}

.birds{
    position: absolute;
    width: 300px;
    left: -30px;
    top: 400px;
}

/************* BUTTONS **************/
.button-green {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 80px;
    padding-right: 80px;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    background: #8ED416;
    border-radius: 12px;
    box-shadow: 0px 9px 0px #365500;
}


/************* HEAD TO HEAD LOBBY **************/
.head-to-head-lobby {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.head-to-head-play-holder {
    align-self: flex-end;
    padding-bottom: 44px;
    padding-right: 44px;
    position: absolute;
    right: 3.45vw;
    bottom: 0vh;
}

.head-to-head-flex {
    background-color: #FFFFFF;
    width: 92.84vw;
    border-radius: 40px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 3.41vh;
    /* margin-bottom: 3.41vh; */
    font-size: 23px;
    margin-bottom: 50px;
    padding: 0;
}

.instructions-box {
    /* padding: 50px; */
    background: #3BA7DA;
    border-radius: 21px;
    width: 600px;
    height: 200px;
    margin: 0 22px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.name-title {
    font-style: italic;
    font-weight: bold;
    font-size: 60px;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.instructions-text{
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.online-box {
    /* margin-left: 42px; */
    /* padding: 50px; */
    background: #FF9401;
    border-radius: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    width: 300px;
    margin: 0 22px;
}

.online-box-number {
    font-style: normal;
    font-weight: bold;
    font-size: 100px;
    line-height: 100px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.online-box-title {
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.inGame-box {
    /* margin-left:42px; */
    /* padding: 50px; */
    background: #37C5CF;
    border-radius: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    width: 300px;
    margin: 0 22px;
}

.inGame-box-number {
    font-style: normal;
    font-weight: bold;
    font-size: 100px;
    line-height: 100px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.inGame-box-title {
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.bird-players-flex {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 92.84vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

/************ Head to Head Slider ************/
.players-in-h2h-slider {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-left: 10px;
    padding-top: 5px;
}

.player-in-h2h-slider {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.bird-player-img-h2h-slider {
    width: 70px;
}

.red-bird-name-h2h-slider {
    color: #D33131;
    font-family: $baloo;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 31px;
}

.black-bird-name-h2h-slider {
    color: #000000;
    font-family: $baloo;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 31px;
}

.worm-in-h2h-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.worm-in-h2h-slider-label {
    color: #A2716E;
    font-family: $baloo;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 31px;
}

.worm-in-h2h-slider-img {
    width: 70px;
}

.head-to-head-slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: #BBE573;
}

/************ Head to Head Result Screen ***********/
.head-to-head-game-lost {
    height: 100vh;
    background-color: #9857DA;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.head-to-head-game-won {
    height: 100vh;
    background-color: #8ED416;
    background-image: url('../images/headToHeadConfetti.svg');
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}

.head-to-head-game-result {
    font-family: $baloo;
    font-style: normal;
    font-weight: bold;
    font-size: 90px;
    line-height: 138px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.head-to-head-game-result-bird {
   height: auto;
   width: 300px;
}

#h2h-adjust-image {
    margin-top: -150px;
    height: 350px;
    width: auto;
}

.head-to-head-game-stats {
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;  
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-top: 25px;
}

.head-to-head-game-xp-won {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 233px;
    height: 50px;
    background: #63A308;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;
}

.head-to-head-game-xp-lost {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 233px;
    height: 50px;
    background: #53198D;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;
}

.head-to-head-game-coins-won {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 50px;
    background: #63A308;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 25px;
}

.head-to-head-game-coins-lost {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 50px;
    background: #53198D;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 25px;
}

.head-to-head-game-woin-image {
    margin-left: 5px;
}

.head-to-head-buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.head-to-head-play-again {
    width: 296px;
    height: 49px;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    background: #38BFFF;
    border-radius: 19px;
    border-width: 2px;
    box-shadow: 0px 9px 0px #00709A;
    margin-right: 10px;
    margin-left: 10px;
}

.head-to-head-return {
    width: 296px;
    height: 49px;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    background: #D33131;
    border-radius: 19px;
    border-width: 2px;
    box-shadow: 0px 9px 0px #8D0101;
    margin-right: 10px;
    margin-left: 10px;
}

/************* Infinity Nav **************/

.infinity-nav {
    width: 100%;
    height: 10vh;
    background: #015587;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.yellow-background-nav {
    background: #FFC300;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 103px;
}

#left-exercise-no-top, #left-exercise-no-top-2, #no-top-3, #no-top-4 {
    padding-top: 0px;
}

#no-top-5 {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    
}

#no-top-6 {
    width: 100%;
    padding-top: 0px;
    font-size: 20px;
}

.infinity-nav-item {
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    min-width: 12vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.chev-holder {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2BA0CC;
    border-radius: 100%;
    margin-right: 10px;
}

.chev-holder:hover {
    cursor: pointer;
    background-color: #33aedb;
}

#inf-left {
    color: #FFFFFF;
    font-size: 23px;
}

/************* LANDING PAGE ***************/
.landing-page-holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 100vw;
    overflow-x: hidden;
}


.landing-nav-logo{
    font-family: $oleo;
font-style: normal;
font-weight: bold;
font-size: 60px;
line-height: 83px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
margin-left: 5.2vw;
color: #FFFFFF;
}

.section-1{
    width: 100%;
    height: 110vh;
    z-index: 0;
    position: relative;
    background: radial-gradient(34.95% 34.95% at 51.5% 57.99%, #FFC701 0%, #FF9401 89.52%), #FF9401;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}


.landing-nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.landing-nav-component{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: #1273A3;
    height:  13.66vh;
}

.landing-main{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    margin-right: 4em;
    background-image: url('../images/landing/Background.svg');
}

.landing-nav-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 50vw;
    padding: 0px;
}

.landing-nav-left{
    width: 50vw;
}

.landing-nav-item{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 32px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #FFC300;
}

.landing-music-ed-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.landing-music-ed-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.landing-music-ed-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 35px;
line-height: 35px;
margin: 0px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;
text-align: left;
color: #FF9401;
width: 100%;
}

.landing-music-ed-description{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 25px;
margin-bottom: 0px;
margin-top: 20px;
line-height: 25px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
text-align: left;
color: #404040;
}

.landing-white-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing-fun-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 35px;
line-height: 35px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;
margin-top: 82px;
color: #3BA7DA;
}

.landing-fun-description{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 25px;
text-align: center;
letter-spacing: 0.03em;
width: 75.83vw;
margin-top: 20px;
margin-bottom: 50px;
color: #404040;
}

.blue-underline{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 25px;
text-align: center;
letter-spacing: 0.03em;
-webkit-text-decoration-line: underline;
        text-decoration-line: underline;

color: #1CB0F6;
}

.landing-fun-circle{
    width: 183px;
height: 183px;
border-radius: 100px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
}

.c1{
    background: #3BA7DA;
}

.c2{
    background: #49BFF7;
}

.c3{
    background: #16C9D4;
}

.c4{
    background: #A1DF36;
}

.c5{
    background: #8ED416;
}

.landing-fun-circles-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 81px;
    width: 77.7vw;
}

.landing-fun-circle-text{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.landing-fun-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.landing-cta{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.landing-cta-title, .landing-cta-description{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 37px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #015587;
}
.landing-cta-description{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    
    color: #015587;
}

.landing-cta-title{
    margin-top: 7vh;
}

.landing-cta-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing-cta-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 65%;
    margin-bottom: 12vh;
}


.landing-footer-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 15.5vh;
    background: #FF9401 ;
}

.landing-footer-text{
    font-family: $josefin;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.white-underlined{
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #FFFFFF;
}

.button{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 24px;
    width: 442px;
    height: 59px;
    letter-spacing: 0.03em;
    border: none;
    color: #FFFFFF;
}


.play-button{
    width: 212px;
    height: 38px;
    border-radius: 12px;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.03em;
    border: none;
    color: #FFFFFF;
    box-shadow: 0px 7px 0px #015587;
    background: #1CB0F6;
}
  
.make-mistakes-wrapper{
    display: inline-block;
    position: relative;
    position: absolute;
    top: -50px;
    left: -20px;
}
.blue{
    background: #1CB0F6;
    border-radius: 19px;
    box-shadow: 0px 10px 0px #015587;
}
.green{
    background: #89D605;
    border-radius: 19px;
    box-shadow: 0px 10px 0px #4E8500;
}
.landing-cta-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 12vh;
}
.green:hover{
    box-shadow: none;
    margin-top: 50px;
}


.blue:hover, .store-item-button:hover{
    box-shadow: none;
    margin-top: 50px;
}

.orange{
    background: #FF9401;
    border-radius: 19px;
    box-shadow: 0px 9px 0px #905400;
}
.orange:hover{
    margin-top: 20px;
    box-shadow: none;
}


.section-2{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

h1.header{
    font-family: $roboto;
font-style: normal;
font-weight: bold;
font-size: 35px;
line-height: 41px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #373737;
}

p.text{
font-family: $roboto;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 23px;
display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #4C4C4C;
}

.line{
    width: 89.4vw;
    height: 0px;
    align-self: center;
    justify-self: center;
    border-bottom: 3px solid #777777;
}


.section-3{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.section-3-bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.section-3-bottom-left, .section-3-bottom-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.section-4{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.text-blue{
font-family: $roboto;
font-style: normal;
font-weight: 900;
font-size: 25px;
line-height: 29px;
display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #1CB0F6;
}

.section-5{
    background: $blue;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 550px;
}

#start-learning-button{
    margin-left: 51px;
    margin-top: 0px;
}

.section-5-top{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

#section-5-white{
    font-family: $roboto;
font-style: normal;
font-weight: bold;
font-size: 35px;
line-height: 41px;
/* identical to box height */

letter-spacing: 0.03em;

color: #FFFFFF;
}

#section-5-white{
    margin: 0px;
}

.footer-holder{
width: 100%;
height: 150px;
top: 281vh;
position: absolute;
z-index: 100;
justify-content: center;
display: flex;
flex-direction: row;
align-items: center;

background: $darkblue;
border-top-right-radius: 43px;
border-top-left-radius: 43px;
}

.text-white{
font-family: $roboto;
width: 1000px;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 29px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #FFFFFF;
}

.logo{
    font-family: $oleo;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 83px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    
    color: #FFFFFF;
    
}


/******************* LANDING PAGE STUDENTS *********/
.join-warbler{
    color: white;
    background: #8ED416;
    margin-top: 45px;
    border-radius: 19px;
    box-shadow: 0px 10px 0px #365500;
    padding-left: 25px;
    padding-right: 25px;
    
    font-family: $josefin;
    font-style: normal;
    line-height: 65px;
    font-weight: 900;
    font-weight: bold;
    font-size: 25px;
    border: none;
    align-items: center;
    text-align: center;

    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);   
}
.join-warbler:hover{
    box-shadow: none;
    margin-top: 55px;
}

.landing-page-students-title-text{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 70px;
    letter-spacing: 0.03em;
    color: #404040;
}
.go-to-TLDR {
    border: none;
    background: none;
    .yellow-underline{
        font-family: $josefin;
        font-style: normal;
        font-weight: bold;
        font-size: 70px;
        line-height: 70px;
        align-items: center;
        text-align: right;
        letter-spacing: 0.03em;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        color: #FFC300;
    }
}


.dark-blue-underline-footer{
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #1273A3;
}

.landing-page-students-title-text-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
}

#lpsttf-1{
    margin-left: 6.18vw;
    justify-content: flex-start;
    margin-top: 10vh;
}

#lpsttf-2{
    justify-content: flex-end;
    margin-right: 4.65vw;
    margin-top: 5vh;
}

#lpsttf-3{
    justify-content: flex-end;
    margin-right: 4.65vw;
}

.landing-page-students-mini-text{
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height */
    align-items: center;
    text-align: right;
    letter-spacing: 0.03em;
    color: #404040;
}

.blue-underline{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height */
    align-items: center;
    text-align: right;
    letter-spacing: 0.03em;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #1273A3;
}

#lpss-2{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 13.34vh;
}

.landing-page-students-section-2-left{
    width: 25.55vw;
    position: relative;
    margin-left: 7.74vw;
    top: -45px;
    left: 30px;
}

.curved{
   width: 100%;
   height: 100%;
}

.emoji-wrapper{
    margin-top: 40px;
}

.emoji1{
    font-family: $calistoga;
    font-style: normal;
    font-weight: normal;
    font-size: 130px;
    line-height: 169px;
    align-items: center;
    text-align: center;
    color: #FF9400;
    transform: rotate(-11.84deg);
}

.landing-page-students-minititle{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    /* identical to box height */
    display: flex;
    align-items: center;
    margin-bottom: 2.22vh;
}

#lpsm-1{
    color: #A560EB;
    margin-left: .86vw;
}

.landing-page-students-minititle-subtext{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    /* identical to box height */
    display: flex;
    align-items: center;
    margin-bottom: 1.70vh;
}

#lpsms-1{
    color: #A560EB;
}

.landing-page-students-text{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 25px;


color: #404040;
width: 64.91vw;
}

.landing-page-students-text-extra-width{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 25px;


color: #404040;
width: 72.91vw;
}

#lpss-3{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15vh;
}

.emoji{
font-style: normal;
font-weight: normal;
font-size: 130px;
line-height: 169px;
text-align: center;

transform: rotate(16.95deg);
}

#lpsm-3, #lpsms-3{
    color: #1CB0F6;
}

#lpss-numbers{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 93.79vw;
    margin-top: 5vh;
}

.landing-page-students-section-3-left{
    margin-left: 7.74vw;
}

.landing-page-students-section-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.lpss-num-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lpss-num{
    font-family: $calistoga;
font-style: normal;
font-weight: normal;
font-size: 100px;
line-height: 130px;
display: flex;
align-items: center;

color: #BCE9FF;
margin-top: 3.66vh;
}

.lpss-num-text{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 26px;
display: flex;
align-items: center;

color: #404040;

}

#lpss-num-1{
    transform: matrix(0.97, -0.2, 0.24, 0.98, 0, 0);
}

#lpss-num-2{
    transform: matrix(0.93, 0.32, -0.37, 0.95, 0, 0);
}

#lpss-num-3{
    transform: matrix(1, 0.05, -0.06, 1, 0, 0);
}

#lpss-num-4{
    transform: matrix(0.92, -0.35, 0.42, 0.93, 0, 0);
}

#lpss-num-5{
    transform: matrix(0.89, 0.4, -0.48, 0.91, 0, 0);
}

.red-lpss{
    color: #E53B3B;
}

.lpss-5-image-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 5vh;
}
.lpss-5-image-group{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 32.88vh;
}

.lpss-5-image-subtitle{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 25px;
/* identical to box height */

text-align: center;

color: #E53B3B;
margin: 0px;
}

.lpss-5-image-text{
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    
    color: #000000;
    width: 24.02vw;
    margin-top: .77vh;
}

.lpss-5-image{
    font-family: $calistoga;
font-style: normal;
font-weight: normal;
font-size: 130px;
line-height: 169px;
align-items: center;
text-align: center;

color: #FF9400;
}

.red-text{
    color: #E53B3B;
}

.lp-push{
    margin-left: 7.74vw;
}
.lp-push-top{
    margin-top: 16.11vh;
}

.lp-push-bottom{
    margin-bottom: 3.88vh;
}

.lpss-6-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 30px;

color: #1273A3;
margin-left: 14.33vw;

}

.lpss-6-subtitle{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 30px;
line-height: 30px;

color: #1273A3;

}

.lpss-6-margins{
    margin-left: 14.33vw;
margin-bottom: 11.33vh;
}

.tldr{
    margin-left: 8.33vw;
    margin-top: 14.44vh;
}

.landing-footer-text{
    font-family: $josefin;
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 105%;
/* or 31px */

align-items: center;
text-align: center;

color: #FFFFFF;
}

.yellow-text{
    color: #FFC300;
}

.landing-students-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background: $orangeGold;
    height: 29.66vh;
}



/****************** LANDING PAGE TEACHERS *********/
.overflow-landing-page-teachers-main, .landing-page-students-flex{
    overflow-x: hidden;
}
.landing-page-teachers-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 70px;
line-height: 70px;
letter-spacing: 0.03em;

color: #404040;
}

.yellow-text{
    color: #FFC300;
}

.orange-text{
    color: #FF9400;
}

.green-text{
    color: #7AC70C;

}

.landing-page-teachers-title-text-flex{
    display: flex;
    flex-direction: row;
    align-items: center;

}

#lptttf-1{
    justify-content: flex-start;
    margin-top: 9vh;
    margin-left: 6.18vw;
}

#lptttf-2{
    justify-content: flex-end;
    margin-right: 4.65vw;
    margin-top: 5vh;
}

#lptttf-3{
    justify-content: flex-end;
    margin-right: 4.65vw;
    margin-top: 4vh;
    margin-bottom: 16.11vh;
}

#lpts-2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 19.66vh;
}

.lpts-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 24.52vw;
    height: 29.39vh;
    margin-left: -4vw;
}

.lpts-right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 65.48vw;
    position: relative;
}

.large-number{
    font-family: $calistoga;
font-style: normal;
font-weight: normal;
font-size: 300px;
line-height: 390px;
display: flex;
align-items: center;
position: absolute;
}

.teacher-number-image{
    position: absolute;
}

#number-1-lpts{
transform: rotate(-12.54deg);
}

.lpts-mini-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 25px;
}

#lpts-2-left{
    margin-top: 4.66vh;
}

.lpts-text{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 25px;
}

.lpts-text-margin-top{
    margin-top: 5.77vh;
}

#classroom-functionality{
    top: -120px;
    left: 65px;
}

#number-1-lpts{
    left: 140px;
    top: -120px
}

#lpts-3{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.lpts-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.lpts-list-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 62.77vw;
    
}

.lpts-list-item-second{
    margin-left: 1.59vw;
    margin-bottom: 2.60vh;
    margin-top: .72vh;
}

.lpts-3-bottom{
    margin-top: 7vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#lpts-3-left{
    margin-top: 12.07vh;
}

#number-2-lpts{
    transform: rotate(12.37deg);
    left: 130px;
    top: -140px;
}

#community-text{
    top: -130px;
    left: 100px;
}

.lpts-3-bottom{
    margin-bottom: 14.02vh;
}

.hover-word:hover{
    cursor: pointer;
}

.lpts-mini-title-b{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 30px;
width: 917px;
}

#lpts-subtitle-text{
    width: 84.09vw;
    text-align: center;
    margin-top: 3vh;
}

#lpts-4{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 11.77vh;
}



/****************** PROFILE PAGE ****************/
.top-section{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 100px;
}

.profile-page-container{
    background: #FFFBF6;
    height: 92.45vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}

.profile-user-info-flex{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.user-stats-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 915px;
height: 276px;

background: #FFFFFF;
box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2), -4px -4px 20px rgba(0, 0, 0, 0.2);
border-radius: 40px;
}

.full-screen{
    width: 100vw;
    height: 100vh;
}

.flex-center-screen{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.profile-fullname{
    font-family: $roboto;
font-style: normal;
font-weight: 900;
font-size: 34px;
line-height: 40px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #373737;
}

.profile-username{
    font-family: $roboto;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 23px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #777777;
}

.profile-bottom-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 47px;
}

.profile-section-title{
    font-family: $roboto;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 35px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
margin-bottom: 10px;
color: #2BA0CC;
}


.profile-section-box{
width: 40vw;
height: 33vh;
background: #FFFFFF;
border-radius: 16px;
z-index: 1;
position: relative;
}

.stats-value{
    font-family: $baloo;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 23px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
margin-top: 20px;

color: #373737;
}

#lightning-bolt{
    margin-right: 8px;
}

.level-bar{
    width: 146.76px;
height: 11.94px;

background: #777777;
border: 2px solid #777777;
border-radius: 100px;
margin-top: 14px;
margin-bottom: 19px;
}

#experience-value{
    margin-top: 0px;
}

.stats-subtitle{
    font-family: $baloo;
    margin-top: 6px;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
width: 163px;

color: #AFAFAF;
}

.stats-value-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.stats-mini-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.user-info-holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.experience-section{
    text-align: center;
}

.experience-bar-stuff{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.experience-bar-without-level{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
}

.stats-section{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.stats-section-mini{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.number{
    font-size:100px; 
}

.subtitle-for-stat{
    font-size: 40px;
}

.activity-and-lessons{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
}

.section-title{
    font-size: 68px;
}

.section-holders{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
}

.activity-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.graph{
    height: 300px;
    background-color: gray;
    border-radius: 10px;
    width: 300px;
}

.button-list{
    width: 300px;
    background-color: gray;
    margin-top: 5px;
    border-radius: 5px;
}

.lessons-section{
    display: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.lesson{
    display: flex;
    flex-direction: row;
    background-color: lightgrey;
    justify-content: space-around;
    align-items: center;
    width: 400px;
    border-radius: 10px;
}

.lesson-tile-left, .lesson-tile-right{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.badges-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.badges-trophy-case-section{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.badges-trophy-case{
    background-color: lightgrey;
    width: 300px;
    height: 400px;
}

.badge-trophy-case-closer-look{
    width: 300px;
    height: 300px;
    background-color: lightgrey;
}

#badges-title{
    margin-bottom: 40px;
}

.trophy-row{
    height: 100px;
    width: 300px;
    border-bottom: black 2px solid;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

.badge{
    background-color: gray;
    height: 75px;
    width: 55px;
}

.sightreading-answer-input-box-container, .answer-input-box-container, .big-answer-input-box-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    align-items: center;
    justify-content: space-around;
    overflow: auto;
}


.listening-mc-answer-input-box-container{
    display: flex;
    margin-bottom: 12px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.note-title-bottom-margin{
    margin-bottom: 1.33vh;
}

.nlc-question-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 700px;
}
.nlc-question-main-width{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
}

.nlc-question-main-width-flexstart{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
}

.nlc-question-selects{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 700px;
    margin-bottom: 16px;
    margin-top: 16px;
}
.nlc-question-selects-width{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 16px;
}

.content-description-input{
    width: 1048px;
    height: 216px;
    background: #FFFFFF;
border: 2px solid #BABABA;
display: flex;
flex-direction: row;
align-items: flex-start;
border-radius: 10px;
font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 23px;
/* identical to box height */
letter-spacing: 0.03em;

color: #BABABA;
padding-left: 19px;
padding-top: 30px;
}

.content-description-input::-moz-placeholder{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 23px;
/* identical to box height */
letter-spacing: 0.03em;

color: #BABABA;
}

.content-description-input:-ms-input-placeholder{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 23px;
/* identical to box height */
letter-spacing: 0.03em;

color: #BABABA;
}

.content-description-input::placeholder{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 23px;
/* identical to box height */
letter-spacing: 0.03em;

color: #BABABA;
}

.lc-render-question{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 3.95vw;
}

.nlc-entry-inputs-flex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
}
.nlc-entry-inputs-flex-listeningmc{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    margin-top: 3.77vh;
}
.nlc-entry-inputs-flex-extra{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    overflow: auto;
}
.select-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 25px;
}

.select-title{
font-family:  $josefin;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 23px;
margin-right: 5px;
/* identical to box height */

letter-spacing: 0.03em;

color: #757575;
}

.note-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 23px;
/* identical to box height */

letter-spacing: 0.03em;

color: #757575;
}
.correct-note-title{
    margin-right: 10px;
}
.correct-container-interval{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.sr-note-input-box{
    width: 102px;
height: 31px;
background: #DEDCDC;
border: none;
font-family:  $josefin;
font-style: normal;
font-weight: normal;
font-size: 23px;
line-height: 112.5%;
/* or 26px */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #535353;
}

.yes-or-no-input{
    margin-left: 8px;
    background: #FFFFFF;
border: 3px solid #BABABA;
box-sizing: border-box;
}

.nlc-add-remove-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    margin-left: 1vw;
    margin-bottom: 26px;
    margin-right: 20px;
}

.nlc-add-remove-button:hover{
    cursor: pointer;
}

.add-button-text{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 23px;
/* identical to box height */

letter-spacing: 0.03em;

color: #1BA8EB;
}

.remove-button-text{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 23px;
/* identical to box height */

letter-spacing: 0.03em;

color: #E53B3B;
}

.nlc-add-remove-buttons{
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: flex-start;
    width: 100%;
}

.interval-container{
    margin-right: 2.96vw;
}

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 25px;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 3px solid #BABABA;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: white;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: flex;
    justify-self: center;
    align-self: center;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 4px;
    top: 4px;
    width: 11px;
    height: 11px;
    border-radius: 20px;
    background-color: #7AC70C;
  }

/****************** ALL LESSONS PAGE ****************/

.lessons-container {
    display: flex;
    justify-content: center;
}

.lessons {
	overflow: auto;
	color: #00000000;
	transition: color 0.3s;
	&:hover {
		color: #FD966A;
	}
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 0;
    margin: 40px;
}

.lesson-icon{
    width: 340px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    background: #FAEFE5;
    box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 27px;
}

.lesson-icon-top {
    height: 400px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color:black;
}

.lesson-icon-bottom{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.lesson-icon-title{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 137.88%;
    /* or 41px */
    display: flex;
    align-items: center;
    color: #F18080;
    margin-left: 2vw;
}

.lesson-icon-description{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15.5px;
    line-height: 137.88%;
    /* or 28px */
    display: flex;
    align-items: center;
    color: #000000;
    margin-left: 2vw;
}

#lesson-icon-button {
    background: #FD966A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 34px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */
    color: #FFFFFF;
    width: 150px;
    height: 6vh;
    margin-bottom: 1.5vh;
    margin-right: 1.5vh;
    margin-top: 1.5vh;
    transition: 200ms all ease;
}

#lesson-icon-button:hover{
    background-color:  #114353;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
}

#lesson-icon-button:active{
    font-size: 22px;
}

/******************* NAVBAR CLASSROOM ********************/
.nav-bar-classroom-container {
    background-color: $darkblue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 137px;
    max-width: 100vw;
}

.nav-user{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: .20;
    padding-right: 10px;
    
}

.left-classroom-nav{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 25px;
    flex: .20;
    
}

.margin-left-nav{
    margin-left: 25px;
}

.nav-center-text{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 40px;
text-align: center;
letter-spacing: 0.03em;

color: #FFFFFF;
flex: .33;
width: -webkit-max-content;
width: -moz-max-content;
width: max-content;
}

.fa-chevron-circle-left {
    position: absolute;
    font-size: 50px;
    background: white;
    border-radius: 50%;
    color: $blue;
    left: 45px;
    top: 43px;
}

.my-classrooms {
    font-weight: 900;
    line-height: 35px;
    padding: 15px;
}

.class-name {
    font-weight: 400;
    border-left: 2px solid white;
    padding: 12px;
}

.putting-in-background-image img {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding-right: 20px;
    padding-left: 20px;
}

.dropdown-container {
    position: relative;
    display: flex;
    align-items: center;
    right: 5.25vw;
}

.dropdown-content {
    display: none;
    position: absolute;
}

.drop-button {
    background: none;
    border: none;
    font-family: $josefin;
    font-weight: 900;
    font-size: 30px;
    color: white;
    letter-spacing: 0.03em;
    margin-right: 10px;
}

/************** CLASSROOM STUDENT VIEW ******************/
.cspc-title-subtext{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 23px;
text-align: center;
letter-spacing: 0.03em;
margin-left: 1.94vw;

}

.gray-text{
    color: #AFAFAF;
}

.cspc-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 34px;
line-height: 34px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;



}

.cspc-margin-left-title{
    margin-left: 3.19vw;
}

.sky-blue-text{
    color: #1CB0F6;
}
.dark-blue-text{
    color: #1273A3;
}

.student-classroom-container{
    background: #DDDDDD;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 3vh;
}

.classroom-student-page-content-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    padding-top: 5vh;
}

#first-row{
    margin-top: 85px;
}

.cspc-right{
    width: 270px;
height: 577px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;

background: #FFFFFF;
border-radius: 44px;
}

.cspc-left{
    width: 74.37vw;
    min-height: 85.55vh;
    margin-top: 4.22vh;
    height: 100%;
    left: 47px;
    top: 175px;
    background: #FFFFFF;
border-radius: 40px;
}

.select-holder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.light-blue-text{
    color: #14D4F4;
}

.lesson-modal-student{
    width: 720px;
    height: 446px;
    background: #FFFFFF;
    border: 2px solid #AFAFAF !important;
    box-sizing: border-box;
    border-radius: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: scroll;
}

.blue-x{
    margin-top: 15px;
    margin-right: 27px;
}

.play-button-modal{
    width: 212px;
    height: 38px;

border-radius: 12px;
font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 20px;
text-align: center;
letter-spacing: 0.03em;
border: none;
color: #FFFFFF;
box-shadow: 0px 7px 0px #015587;
background: #1CB0F6;
margin-bottom: 27px;
margin-top: 26px;
}

.lesson-modal-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 40px;
/* identical to box height */

text-align: center;
letter-spacing: 0.03em;

color: #7AC70C;
}

.lesson-modal-author{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #373737;
margin-bottom: 21px;
}

.blue-x:hover{
    cursor: pointer;
}

.lesson-modal-stats-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.lesson-modal-flex-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
    margin-right: 26px;
    margin-bottom: 21px;
}

.lesson-modal-stat-box{
    width: 204px;
height: 224px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;


border-radius: 28px;
}

.lime-green{
    background: #B0E94D;
}

.box-green{
    background: #8EE000;
}

.dark-green{
    background: #7AC70C;
}

.lesson-modal-stat-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 20px;
text-align: center;
letter-spacing: 0.03em;
width: 152px;

color: #373737;
margin-top: 24px;
margin-bottom: 33px;
}

.lesson-modal-stat{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 50px;
line-height: 50px;
text-align: center;
letter-spacing: 0.03em;

color: #FFFFFF;
}

/*************** LEADERBOARD ********************/
.cspc-leaderboard-title-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 4.22vh;
    margin-right: 1.59vw;
    margin-left: 1.875vw;
}

.leaderboard-title{
    text-align: center;
}

.cspc-leaderboard-inner{
    width: 211px;
height: 365px;
margin-top: 24.93px;

background: #FFC04D;
border-radius: 29px;
margin-bottom: 22px;
}

.cspc-leaderboard-text-subtitle{
    font-family: $josefin;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 20px;
/* identical to box height */

text-align: center;

color: #D33131;
}

.cspc-leaderboard-text{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 15px;
text-align: center;

color: #373737;
}

.cspc-leaderboard-bottom-text-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3.44vh;
}


/*************** CLASSROOM TEACHER VIEW ****************/
.classroom-home-teacher-container {
    background-color: #E5E5E5;
    width: 100vw;
    padding-bottom: 50px;
}

.non-navbar-content-container {
    position: relative;
    padding-bottom: 5vh;
}

.leaderboard-header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    width: 100%;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.03em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 4.22vh;
    margin-right: 1.59vw;
    margin-left: 1.875vw;

    color: #D33131;
}

.leaderboard-students{
    background: #FFC04D;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1.2vh 1.2vw;
    min-height: 30vh;
    width: 211px;
    height: 365px;
    
    background: #FFC04D;
    border-radius: 29px;
    margin-bottom: 22px;
}

.leaderboard-entry{
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 23px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #373737;
    height: 25px;
}

.leaderboard-entry-xp{
    font-weight: normal;
    color: #D33131;
}
.leaderboard-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3.44vh;
    width: 100%;
}

.leaderboard-red-text{
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4em;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #D33131;
    margin-bottom: 8px;
}

.leaderboard-subtext{
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1em;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #373737;

}

/*************** CLASSROOM TEACHER VIEW ****************/
.classroom-home-teacher-container {
    background-color: #E5E5E5;
    width: 100vw;
    padding-bottom: 50px;
}

.tab-text{
    font-family: $josefin;
}

.non-navbar-content-container {
    position: relative;
}

.selected-page-container {
    position: relative;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2), -4px -4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto;
    font-style: normal;
    letter-spacing: 0.03em;
}

.left-menu-container {
    position: relative;
    color: #afafaf;
    display: flex;
    flex-direction: row;
    font-family: $roboto;
    font-weight: 900;
    font-size: 30px;
    width: 900px;
    margin-left: 10vw;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: space-around;
}

.left-menu-container div:hover{
    cursor: pointer;
}

.left-menu-container span i {
    font-size: 30px;
}

.left-menu-container span {
    padding-right: 4px;
    outline: none;
}

.left-menu-container .selected {
    color: $lightblue;
}

.top-stuff {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 60px;
    margin-right: 57px;
    padding-top: 60px;
    padding-bottom: 30px;
}
.top-stuff-special {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 60px;
    margin-right: 57px;
    padding-top: 60px;
    padding-bottom: 30px;
}

.left-text-cp {
    display: flex;
    align-items: baseline;
    left: 60px;
    top: 58px;
}

.left-text-cp .page-title {
    display: flex;
    font-weight: 900;
    font-size: 34px;
    font-family: $josefin;
}

.left-text-cp .number-of-students {
    display: flex;
    font-size: 23px;
    font-weight: bold;
    color: #afafaf;
    padding: 10px;
    font-family: $josefin;
}

.right-buttons {
    right: 60px;
    top: 42px;
    font-family: $roboto;
    display: flex;
}

.right-buttons .edit-button {
    position: relative;
    background: none;
    border: 4px solid #afafaf;
    box-sizing: border-box;
    border-radius: 29px;
    width: 174px;
    height: 52px;
    font-size: 23px;
    color: #afafaf;
    font-family: $josefin;
    letter-spacing: 0.03em;
    font-weight: 900;
    outline: none;
}

.right-buttons .invite-students-button {
    position: relative;
    background: none;
    box-sizing: border-box;
    background-color: $yellow;
    color: white;
    border-radius: 29px;
    border: 4px solid $yellow;
    width: 253px;
    height: 52px;
    margin-left: 36px;
    font-size: 23px;
    font-family: $josefin;
    letter-spacing: 0.03em;
    font-weight: 900;
    outline: none;
}

.example-student-container {
    position: relative;
    width: 455px;
    height: 132px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.07), -4px -4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 25px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 19.5px;
    margin-bottom: 19.5px;
}

.example-student-container img {
    position: relative;
    width: 78px;
    height: 68px;
    top: 32px;
    left: 32px;
    align-items: center;
    border-radius: 107.5px;
}

.example-student-container span {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.03em;
}

.example-student-container .student-name {
    position: absolute;
    left: 120px;
    top: 43px;
    font-family: $josefin;
}

.example-student-container .experience-points {
    position: absolute;
    bottom: 40px;
    font-family: $josefin;
    left: 120px;
    color: #afafaf;
}

.student-flexbox {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
}

.left-dropdown {
    position: relative;
    display: flex;
    margin-left: 47px;
    color: white;
    background: $blue;
    width: 301px;
    height: 65px;
    font-family: $roboto;
    font-weight: 900;
    font-size: 34px;
    line-height: 39.84px;
    letter-spacing: 0.03em;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
}

.left-dropdown i {
    position: absolute;
    right: 25px;
}

.example-lesson-container {
    width: 274px;
    height: 132px;
    margin-bottom: 23px;
    margin-right: 27px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.07), -4px -4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blue-lesson{
    background: #1CB0F6;
}

.gray-lesson{
    background: #AFAFAF;
border: 2px solid #AFAFAF;
}

.lesson-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 20px;
/* identical to box height */

letter-spacing: 0.03em;
margin-bottom: 11px;


}

.blue-lesson-title{
    color: #373737;
}

.num-students-finished{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 15px;
/* identical to box height */
letter-spacing: 0.03em;
margin-left: 34px;

}

.blue-lesson-num{
    color: #FFFFFF;
}

.gray-lesson-title{
    color: #DDDDDD;
}

.gray-lesson-num{

color: #DDDDDD;
}

.no-lessons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#no-lessons{
    height: 10vh;
}
.lesson-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 57px;
}

#lesson-list-lesson{
    height: 20vh;
}

#unpublished-lesson{
    border: 1px solid red;
}

.example-lesson-container .lesson-title {
    margin-top: 43px;
    margin-left: 33px;
    font-family: $josefin;
    font-weight: 700;
    font-size: 20px;
    line-height: 23.44px;
    letter-spacing: 0.03em;
    color: #373737;
}

.cspc-lesson-list{
    margin-left: 3.19vw;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    overflow-x: scroll;
    margin-bottom: 7.11vh;
    margin-top: 2.55vh;
    font-family: $josefin;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #AFAFAF;
}

.teacher-lessons-view-container {
    min-height: 800px;
}

.lesson-modal{
    z-index: 35;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 100%;
    font-family: $roboto;
    font-size: 1.4em;
}

#lesson-modal-body{
    min-height: 88.5vh;
    min-width: 65vw;
    overflow-y: scroll;
    padding-top: 20px;
}

.lesson-modal-class-stats{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
}

.lesson-modal-complete-title{
    font-family: $josefin;
font-style: normal;
font-weight: 600;
margin-top: 20px;
font-size: 22px;
line-height: 22px;
/* identical to box height */
width: 90%;
margin-bottom: 10px;
text-align: left;
letter-spacing: 0.03em;
}

.lmcs-title{
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: bold;
    /* identical to box height */
    width: 90%;
    font-size: 1.3em;
    letter-spacing: 0.03em;

    color: #373737;
    margin-bottom: 10px;
}
.lesson-modal-students{
    margin-top: 1vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 375px;
    min-height: 183px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #DDDDDD;
    border-radius: 17px;
}

.lesson-modal-students-section{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 90%;
    justify-content: space-around;
    overflow: scroll;
}

.lesson-modal-student-revised{
    width: 720px;
    height: 446px;
    background: #FFFFFF;
    border: 2px solid #AFAFAF !important;
    box-sizing: border-box;
    border-radius: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

}

.lesson-modal-student{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5vh;
    min-width: 5vw;
    max-width: 10vw;
    border-radius: 25px;
    margin: 12.5px;
    color: black;
    font-family: Josefin Sans;
    font-style: normal;
}

.lesson-modal-student-new{
    margin-top: 5px;
    margin-bottom: 5px;
}

#lesson-modal-student-complete{
    color: #7AC70C;
}

#lesson-modal-student-incomplete{
  color: #D33131;
}

#lesson-complete{
    
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 1.15em;
}

#lesson-incomplete{
   
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 1.15em;
}

/************* All Classes ************/
.all-classes{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: $offwhite;
    
}

.add-class {
    width: 779px;
    height: 250px;
    background: #FFFFFF;
    border: 1px solid #373737 !important;
    box-sizing: border-box;
    border-radius: 27px;
    padding-left: 10px;
    padding-right: 10px;
}

.join-class {
    width: 779px;
    height: 250px;
    background: #FFFFFF;
    border: 1px solid #373737 !important;
    box-sizing: border-box;
    border-radius: 27px;
    padding-left: 10px;
    padding-right: 10px;
}

.join-modal-join-button{
    background: #7AC70C;
    border-radius: 16px;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    border: none;
    width: 220px;
    height: 51px;
    margin-left: 20px;
    padding-top: 4px;
}

.add-modal-title{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #373737;
}

.add-modal-create-button{
    background: $limeGreen;
    border-radius: 16px;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    border: none;
    width: 174px;
    height: 51px;
    margin-left: 20px;
    padding-top: 4px;
}

.add-modal-input-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 42.15vw;
}

.add-modal-input{
    width: 412px;
    height: 51px;
    background: #C4C4C4;
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: 0.03em;
    color: #505050;
    padding-left: 10px;
    border: none;
}

.add-modal-rows{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding: 10px 0 40px 0;
}

.add-classes-div{
    height: 24vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 1.8em;
    background-color: $darkblue;
}

.add-classes-left{
    width: 40vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 60px;
display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #FFFFFF;
}

.add-classes-right{
    width: 30vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.add-classes-button{
    background-color: A560EB;
    height: 6vh;
    width: 20vw;
    font-size: 0.8em;
    font-family:  $josefin;
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 26px;
text-align: center;
letter-spacing: 0.03em;

color: white;
    border: none;
    background: #89D605;
    border-radius: 19px;
    box-shadow: 0px 9px 0px #6BB700;

}

.add-classes-button:hover{
    cursor: pointer;
    box-shadow: none;
    margin-top: 25px;
}

.add-classes-button:focus{
    outline: none;
}

.all-classes-bottom-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.all-classes-bottom{
    width: 100vw;
    min-height: 330px;
    height: 70vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.welcome-message-container {
    height: calc(75vh - 68px);
    font-family: $josefin;
    padding-top: 6vh;
    font-size: 4.7em;
    font-style: italic;
    font-weight: bold;
    color: #37C5CF;
    display: flex;
    align-items: center;
    width: 95vw;
    padding-left: 5vw;
}

.flex-center-tile{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 10.5em;
    height: 10.5em;
    background: #8ED416;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2), -4px -4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 28px;
    transition: 100ms ease all;
}

.flex-center-tile:hover{
    cursor: pointer;
    box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.2), -4px -4px 20px rgba(0, 0, 0, 0.2);
}

.flex-center-tile:active{
    background: #78b115;
}

.plus-icon{
    width: 4.5em !important;
    height: 4.5em !important;
    color: white;
}

.class-view{
    margin-top: 3vh;
    margin-bottom: 3vh;
    width: 30vw;
    height: 60vh;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    min-height: 320px;
    min-width: 300px;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2), -4px -4px 20px rgba(0, 0, 0, 0.2);
    transition: 200ms ease all;
}

.class-view:hover{
    cursor: pointer;
    box-shadow: 3px 5px 20px 9px rgba(0, 0, 0, .2), -4px -4px 20px rgba(0, 0, 0, 0.2);
}

.class-view-invite {
    font-size: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
}

.gear-container {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0;
    align-items: center;
    width: 100%;
    #gear-icon {
        button {
            background: none;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 15px;
            padding: 0;
        }
    }
}

#class-view-top{
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    width: 100%;
    font-weight: bold;
}

#class-view-middle{
    height: 20vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    .student-view {
        margin: 0 0.8vw;
    }
}

#class-view-bottom {
    width: 100%;
    height: 10vh;
    background: #1CB0F6;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

#infinity-view {
    background-color: $lightblue;
    color: #FFFFFF;
}

#community-view {
    background-color: #E37642;
    color: white;
}

#infinity-view-bottom{
    width: 100%;
    height: 10vh;
    background: #D33131;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF; 
}

.tile-view-top{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

#allclasses-rocket{
    /*position: absolute;
    width: 60%;
    height: 60%;
    right: 25%;
    top: 30%;*/
    /*width: 100%;*/
    height: 150%;
    transform: rotate(-5.39deg);
    /*
    position: relative;
    width: 100%;
    height: 100%;
    right: 10%;
    bottom: 20%;
    */
}

.head-div{
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#allclasses-in-class{
    /*position: absolute;
    width: 60%;
    height: 60%;
    right: 25%;
    top: 30%;*/
    /*width: 100%;*/
    height: 150%;
    transform: rotate(-5.39deg);
    /*3w
    position: relative;
    width: 100%;
    height: 100%;
    right: 10%;
    bottom: 20%;
    */
}

.ac-special-tile-div{
    height: 80%;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 33vw;
}

#class-view-top{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.03em;
    color: #373737;
}

.class-view-top-section{
    width: 100%;
}

#star{
    color: #FFB020;
    font-size: 1.7em;
}

.star-container {
    display: flex;
    justify-content: flex-end;
    margin: 15px -15px;
    align-items: center;
    width: 100%;
}

.smallmodal{
    width: 505px;
    height: 218px;
    border-radius: 27px;
}

.code-modal{
    background: #FFFFFF;
    border: 1px solid #373737;
    box-sizing: border-box;
    border-radius: 27px;
}

.code-modal-rows{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 40px;
    justify-content: space-around;
    height: 100%;
}

.code-modal-render-x:hover{
    cursor: pointer;
}

.code-modal-render-x{
    margin-right: 1.5vw;
}

.code-modal-over{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.code-modal-x{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.code-modal-title{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    margin-right: 20px;
    line-height: 30px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #7AC70C;
}

.code-modal-code{
    font-family: $josefin;
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    vertical-align: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.03em;

    color: #363434;
}

.birds-div{
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.green-bird{
    left: 40%;
    top: 30%;
}

.yellow-bird{
    top: 10%;
    right: 40%;
}
/***** Testing Vexflow ******/
canvas{
    background: #EED;
    border: 10px solid #DDDDCC;
}

.vex-page{
    /* width: 20vw;
    height: 20vh;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/******************* Exercise Nav **********/ 

.main-exercise-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.left-exercise-nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-left: 25px;
    
}



.prof-left-exercise-nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
}

.right-exercise-nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    padding-right: 25px;
}

.right-exercise-nav-col{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.arrow-back-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;
    margin-right: 19px;
    border-radius: 100px;
}

.exercise-nav-level{
    font-family: $josefin;
    font-weight: bold;
    color: #1CB0F6;
    font-size: 30px;
}

.arrow-back-button:hover{
    cursor: pointer;
}

.blue-arrow{
    background-color: #2BA0CC;
}

.purple-arrow{
    background-color: #9E5BDD;
}

.back-arrow{
    width: 20px !important;
    height: 20px !important;
    color: white !important;
}

.back-word{
font-family: $josefin;
font-style: normal;
font-weight: 900;
font-size: 30px;
line-height: 35px;
display: flex;
align-items: center;
letter-spacing: 0.03em;


}

.blue-word{
    color: #2BA0CC;
}

.white-word{
    color: white;
}

.progress-bar{
    width: 492px;
    height: 40px;
    background: #E5E5E5;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    font-family: $roboto;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 26px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;
border-radius: 20.2px;

color: #2BA0CC;

}

.progress-filler{
    height: 40px;
    background: #E5E5E5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-family: $roboto;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 26px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;
border-radius: 20.2px;

background-color: #2BA0CC;
transform: ease-in 1s;
}

.exercise-nav-xp{
    color: #1CB0F6;
    font-size: 34px;
    padding-top: 25px;
    font-family: $josefin;
font-style: normal;
font-weight: bold;
line-height: 30px;
/* identical to box height */
text-align: right;
letter-spacing: 0.03em;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
#heart {
    color: red;
    font-size: 3em;
    margin-left: 5px;
    margin-right: 5px;
}

.lives{
    height: 80%;
    width: 15vw;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50px;
}

/*************** Sightreading ******************/
.letter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 121px;
    border-radius: 22px;
    font-family: $baloo;
    text-align: center;
font-style: normal;
font-weight: bold;
color: white;
font-size: 70px;
line-height: 108px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
}


.blank{
    display: none;
}
.orange-letter{
    background: #FF9400;
}
.purple-letter{
    background: #A560EB;
}

.green-letter {
    background:  #8EE000;
}
.brown-letter{
    background: #A86425;
}

.light-blue-letter {
    background: #14D4F4;
}

.pink-letter {
    background: pink;
}

.red-letter{
    background: #E53B3B;
}

.sightreading-main-page{
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.sightreading-title{
    font-family: $baloo;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 61px;
display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #404040;
}

.sightreading-exercise-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 1200px;
}

.sightreading-exercise-pieces{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sightreading-letters-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 30px 30px;

background: rgba(196, 196, 196, 0.4);
border-radius: 30px;
}

.sightreading-div-location{
    width: 0.25vh;
    color: blue;
    background-color: red;
    border-radius: 50px;
    height: 120px;
    border-left: 1px solid red;
}

.sightreading-note-divs{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    background-color: #FFC300;
    height: 130px;
    max-height: 130px;
    width: 1250px;
    border-radius: 30px;
}

.next-button-holder{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100vw;
    margin-right: 2vw;
}

.next-button{
    background: #FFC300;
    border: 4px solid #FFC300;
    box-sizing: border-box;
    border-radius: 29px;
    width: 254px;
    height: 57px;
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 30px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #FFFFFF;

    color: #FFFFFF;
    text-align: center;
    justify-content: center;
    margin-right: 25px;
    margin-bottom: 20px;
}

.next-button:hover{
    cursor: pointer;
}

/** Listening - Intervals **/
.listening-interval-exercise {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listening-main-page{
    width: 100%;
}

.listening-bar{
    border-radius: 21px;
    background-color: #2BA0CC;
    width: 643.91px;
    height: 42.78px;
}

.listening-title{
    font-size: 1.6em;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#listening-play-bar-ic{
    margin-bottom: 20px;
}

.play-background{
    border-radius: 100px;
    background-color: #2BA0CC;
    width: 69px;
    height: 63px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 31px;
    transition: 100ms ease all;
}

.play-background:hover{
    cursor: pointer;
    background: $darkblue;
}

#play-intervals-button{
    fill: white !important;
    width: 20px;
    height: 20px;
    color: white !important;
}
.intervals-dots{
    height: 10vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.intervals-dot{
    margin: 0px 0.5vw;
    height: 5vw;
    width: 5vw;
    background-color: #bbb;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#intervals-icon-2{
    font-size: 3.5em;
    margin: 0px 0.5vw;
}
#intervals-icon{
    color: white;
    font-size: 1.8em;
}
.intervals-instructions{
    font-family: $baloo;
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 57px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #404040;
    width: 90%;
}

.pitch-vex-page {
    .vex-page {
        height: 5vh;
        .stave {
            height: 5vh;
            svg {
                height: 300px;
            }
        }
    }
}
.listening-play-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.interval-options{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.intervals-results{
    font-size: 3em;
    margin: 2vw 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#intervals-complete{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.intervals-percent{
    margin: 0px 0.5vw;
    height: 20vw;
    width: 20vw;
    background-color: #bbb;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2.2em;
    margin: 0px 3vw;
}

#yes-div{
    width: 437px;
    height: 320px;
    

    border-radius: 54px;
    font-family: $baloo;
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    display: flex;
    vertical-align: middle;
    line-height: 108px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    
    color: #FFFFFF;
    background: #7AC70C;
    transition: 100ms ease all;
    border-radius: 54px;
}

#yes-div:hover{
    background: #71af1b;
}

.option-label:hover{
    cursor: pointer;
}

.interval-choice:hover{
    cursor: pointer;
}

#yes, #no{
    display: none;
}

.option-label{
    width: 100%;
}

#no-div{
width: 437px;
height: 320px;


background: #D33131;
transition: 100ms ease all;
border-radius: 54px;
font-family: $baloo;
font-style: normal;
font-weight: 700;
font-size: 70px;
display: flex;
vertical-align: middle;
line-height: 108px;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #FFFFFF;
}

#no-div:hover{
    background: #a82929;
}

#yes-div:hover, #no-div:hover{
    cursor: pointer;
}

.listening-main-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/********** Listening MC *********/
.listening-mc-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mc-options{
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.mc-toprow, .mc-bottomrow{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.mc-answer-button{
width: 534px;
height: 193px;
margin-left: 20px;
margin-right: 20px;
margin-bottom: 20px;
margin-top: 20px;
background: #FFFFFF;
box-sizing: border-box;
border-radius: 47px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -4px -4px 10px rgba(0, 0, 0, 0.25);
}

#orange-answer{
    border: 6px solid #FF9400;
}

#green-answer{
    border: 6px solid #8EE000;
}

#pink-answer{
    border: 6px solid #E47EA6;
}

#blue-answer{
    border: 6px solid #14D4F4;
}

#purple-answer{
    border: 6px solid #A560EB;
}

/********* RHYTHM ACTIVITY *********/
.rhythmPage{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.rhythmPage-rest{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rhythmPage-ic-student{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.rhythm-ic-wrapper{
    height: 100%;
}


.rhythm-stave{
    position: relative;
}

.rhythm-buttons{
    margin-top: 5vh;
    width: 40vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.rhythm-vex-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 962px;
    height: 322px;
    background: #FFFFFF;
    border: 2px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -4px -4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 47px;
    margin-bottom: 6vh;
}

.countdown-text{
    font-family: $baloo;
font-style: normal;
font-weight: bold;
font-size: 50px;
line-height: 77px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #2BA0CC;
}

.countdown-holder{
    height: 89px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rhythm-spacebar{
    width: 547px;
    height: 79px;
    background: #FF9400;
    box-shadow: 4px 4px 10px #FFC300, -4px -4px 10px #FFC300;
    border-radius: 25px;
    margin-top: 42px;
}
 

.rhythm-play-answer-button{
    width: 20vw;
    max-width: 385px;
    height: 52px;
    left: 382px;
    top: 681px;
    
    font-weight: bold;
    font-size: 27px;
    line-height: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.03em;
    font-family: $baloo;
    
    color: #FFFFFF;
    background-color: #8EE000;
    border: none;
    text-align: center;
    border-radius: 29px;
}

.rhythm-attempt-button{
    background: #1CB0F6;
    border-radius: 29px;
    color: #FFFFFF;
    width: 12vw;
    max-width: 300px;
    height: 52px;

    font-weight: bold;
    font-family: $baloo;
    font-size: 27px;
    line-height: 27px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.03em;
    border: none;
}

/************ CONTENT ******************/
.content-title{
font-family: $baloo;
font-style: normal;
font-weight: 800;
font-size: 37px;
line-height: 57px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #404040;
}

.content-exercise{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
}

.content-main-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex: 1;
}

.content-description{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 35px;
line-height: 35px;
letter-spacing: 0.03em;
min-height: 85%;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
flex: 1;
color: #404040;
width: 86.87vw;
margin-top: 37px;
}


/*********** PITCH-MATCHING ************/

.instructions {
    font-family: $baloo;
    font-style: normal;
    font-weight: bold;
    font-size: 37px;
    line-height: 57px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #404040;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pitchmatching-exercise{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
}

.pitch-vex-page{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 604px;
    height: 226px;
    background: #FFFFFF;
    border: 2px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -4px -4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 47px;
}

#submit-pitchmatching-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FF9400;

    border: None;
    font-family: $baloo;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 49px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    width: 363px;
    height: 363px;
    border-radius: 1000px;
    color: #FFFFFF;
}

#submit-pitchmatching-button-green{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #7AC70C;

    border: None;
    font-family: $baloo;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 49px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    width: 363px;
    height: 363px;
    border-radius: 1000px;
    color: #FFFFFF;
}

#pitchmatching-result{
    height: 5vh;
    color: red;
    font-weight: 300;
    font-size: 1.5em;
}
.pitch-main{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.play-button-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.music-note-svg{
    margin-left: 30px;
}

.left-pitch-flex{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.play-bar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 51px;
}

.pitch-bar{
    width: 510px;
    height: 41px;
    background:  #2BA0CC;
    border-radius: 21px;
}


/******************** LESSON CREATION ************/
.lesson-creation-nav{
    display: flex;
    width: 100%;
    height: 143px;
    background: #015587;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}   

.lesson-creation-nav-publish-button, .lesson-creation-nav-save-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    border: none;
    margin-right: 21px;
    height: 47px;

    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;

}

.lesson-creation-nav-publish-button{
    width: 152px;
}

.lesson-creation-nav-save-button{
    width: 122px;
}
.lesson-creation-nav-save-button{
    background: #BCE9FF;
    color: #1BA8EB;

}

.lesson-creation-nav-publish-button{
    background: #1BA8EB;
    color: #FFFFFF;
}

.lesson-creation-nav-top-half{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 1;
}

.back-group{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.lesson-creation-nav-bottom-half{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.lesson-creation-nav-create, .lesson-creation-nav-preview{
    font-family: $roboto;
font-style: normal;
font-weight: 500;
font-size: 35px;
line-height: 41px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;

color: $lightblue;
}

.lesson-creation-nav-preview:hover{
    cursor: pointer;
}

.lesson-creation-nav-create:hover{
    cursor: pointer;
}

.lesson-creation-nav-create{
    margin-right: 50px;
}

div.lc-nav-selected {
    color: #1BA8EB;
    border-bottom: 10px #1BA8EB solid;
    font-family: $josefin;
}

.lc-nav-unselected{
    padding-bottom: 10px;
    color: white;
    font-family: $josefin;
}


.title-panel-container{
    width: 1284px;
    height: 167px;
    margin-top: 65px;
    background: #FFFFFF;
    border-radius: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 12px solid #1BA8EB;
}

.lesson-creation-main-page{
    padding-bottom: 20px;
}

.lesson-background{
    background: #E5E5E5;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lesson-creation-text-section{
    display: flex;
    flex-direction: column;
}

#lesson-title-input{
    border: none;
    background-color: transparent;
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 50px;
line-height: 59px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #000000;
padding-left: 57px;
margin-top: 10px;
}

#lesson-description-input{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 23px;
line-height: 27px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #3C3C3C;
border: none;
background-color: none;
padding-left: 57px;
}

.lesson-creation-nav-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.loading-preview{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 50px;
line-height: 50px;
/* identical to box height */

letter-spacing: 0.03em;
margin-top: 40px;
color: #000000;
}

@media only screen and (max-width: 800px){


.lc-individual-page-container{
    width: 82vw;
    min-height: 401px;
    height: 100%;

    background: #FFFFFF;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
}
}

.lc-individual-page-container{
    width: 1185px;
    min-height: 401px;
    height: 100%;

    background: #FFFFFF;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
}

.lc-individual-page-container-top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 40px;
}
.lc-individual-page-container-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.page-description-text{
    width: 625px;
    height: 75px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: #757575 10px solid;
    background-color: #F3F3F3;
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 29px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
padding-left: 10px;
margin-left: -1vw;
color: #757575;
}

.lc-page-icon{
    width: 45px !important;
    height: 45px !important;
    color: rgba(0, 0, 0, 0.54);
}


.Listening-Multiple-Choice-left-border-color{
    border-left: #FFA933 10px solid;
}

.Content-left-border-color{
    border-left: #757575 10px solid;
}

.Listening-Intervals-left-border-color{
    border-left: #FFA933 10px solid;
}

.Pitch-Matching-left-border-color{
    border-left: #E53B3B 10px solid;
}

.Sightreading-left-border-color{
    border-left: #A560EB 10px solid;
}

.Rhythm-Sensing-left-border-color{
    border-left: #8EE000 10px solid;
}

.custom-select{
    width: 200px;
}

.react-select-container{
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    width: 200px;
    height: 69px;
    
}

.react-select__indicator-separator{
    display: none;
}

.react-select__indicator{
    fill: #757575;
}

.react-select__value-container{
    font-family: $roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;

    color: #757575;
}

.react-select__options{
    color: #757575;
}

.lesson-creation-all-pages{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.add-page-button-hidden-text{
    width: 96px;
    
}

.hide-page-button-hidden-text{
    width: 40px;
}

.add-page-button-hidden-text, .hide-page-button-hidden-text{
    position: absolute;
    z-index: 1;
    top: 28.5px;
    left: 0px;
    font-family: $josefin;
    height: 26px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.03em;

    color: #E5E5E5;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: 200ms left ease;
}

.lc-individual-page-copy-button-hidden-text, .lc-individual-page-delete-button-hidden-text{
    position: relative;
    z-index: -1;
  visibility: 1;
  font-family: $roboto;
  width: 61px;
  height: 26px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #E5E5E5;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: 200ms left ease;
  background: #757575;
  text-align: center;
}

.lc-individual-page-delete-button-hidden-text{
    margin-left: 10px;
}

.lc-individual-page-hidden-text-containers{
    position: absolute;
    top: -66px;
    transition: 200ms ease all;
}

.lc-i-page-text-and-buttons{
    position: relative;
}

.add-page-button-side{
    display: flex;
    flex-direction: row;
    position: relative;
}

.lc-individual-page-container-buttons{
    width: 100px;
}

.add-page-button-side-absolute{
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: 25vh;
}
.hide-page-button-side-absolute{
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: 11vh;
}

.lc-individual-page-copy-button-hidden-text-outer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width:  140px;
}

.add-page-button, .hide-page-button{
    width: 73px;
    z-index: 2;
    height: 77px;
   
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.add-page-button{
    background: #1BA8EB;
}


.hide-page-button{
    background: #7CD0F9;
}

.add-page-button:hover, .hide-page-button:hover{
    cursor: pointer;
}



.add-page-button-side-absolute:hover .add-page-button-hidden-text{
    visibility: 1;
    left: 70px;
    top: 28.5px;
    background-color: #757575;

}

.hide-page-button-side-absolute:hover .hide-page-button-hidden-text{
    visibility: 1;
    left: 70px;
    top: 28.5px;
    background-color: #757575;

}

.add-page-plus-circle{
    width: 43px !important;
    height: 43px;
    color: white;
}

.lc-i-page-text-and-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.lc-individual-page-container-copy-button:hover ~ .lc-individual-page-container-copy-hidden-text{
    top: -91px;
    z-index: 1;
    visibility: visible;
}


.lc-individual-page-container-copy-hidden-text, .lc-individual-page-container-delete-hidden-text{
    visibility: hidden;
    transition: 200ms ease all;
    top: -70px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */
    font-family: $josefin;
    letter-spacing: 0.03em;
    color: #E5E5E5;
    text-align: center;
    background: #757575;
    text-align: center;
    position: relative;
    width: 61px;
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    left: -4px;
}
.lc-individual-page-container-copy-button:hover ~ .lc-individual-page-container-copy-hidden-text{
    position: relative;
    z-index: 1;
    visibility: visible;
    background-color: #757575;
  font-family: $josefin;
    top: -90px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.03em;
  color: #E5E5E5;
  text-align: center;
  background: #757575;
  text-align: center;
}

.lc-individual-page-container-delete-button:hover ~ .lc-individual-page-container-delete-hidden-text{
    position: relative;
    z-index: 1;
    visibility: visible;
    background-color: #757575;
  font-family: $josefin;
    top: -90px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.03em;
  color: #E5E5E5;
  text-align: center;
  background: #757575;
  text-align: center;
}

.lc-individual-page-container-copy-button:hover, .lc-individual-page-container-delete-button:hover{
    cursor: pointer;
}


/************************* LOGIN ****************/
.login-holder{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #0BB898;
    height: 100%;
    min-height: 100vh;
    min-width: 100vw;
    width: 100%;
}

.login-top-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    width: 100vw;
}

.signup-top-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    width: 100vw;
}


.login-button{
    border-radius: 19px;
    width: 198px;
    height: 65px;
    font-family: $josefin;
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 29px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    border: none;
    color: #FFFFFF;
}

.login-x{
    margin-left: 40px;
}


.login-input-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 60;
}

.login-title{
    font-family: $oleo;
font-style: normal;
font-weight: bold;
font-size: 6.66vh;
line-height: 9.22vh;

.login-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 20vh;
}
/* identical to box height */

letter-spacing: 0.03em;

color: #FFFFFF;
}

.login-input{
    background: #F0F0F0;
    border: 2px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 25px;
    font-family: $josefin;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    width: 460px;
    height: 65px;
    color: #8D8D8D;
    padding-left: 25px;
    margin-bottom: 16px;
}

.login-input-half{
    background: #F0F0F0;
    border: 2px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 25px;
    font-family: $josefin;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    width: 200px;
    height: 65px;
    color: #8D8D8D;
    padding-left: 25px;
    margin-bottom: 16px;
}

.login-button-yellow{
    width: 442px;
    height: 66px;

    background: #89D605;
    border-radius: 19px;
    border: none;
    font-family: $josefin;
font-style: normal;
font-weight: 900;
font-size: 25px;
line-height: 29px;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #FFFFFF;
}
.login-button-yellow:hover{
    border-radius:none;
}

.login-bottom-text{
    font-family: $josefin;
font-style: normal;
font-weight: 700;
font-size: 2.77vh;
line-height: 3.00vh;
letter-spacing: 0.03em;
flex: 1;
color: white;
margin-bottom: 40px;
}

.login-x{
    fill: #FFFFFF !important;
    color: white;
    width: 31px !important;
    height: 31px !important;
}

.login-x:hover{
    cursor: pointer;
}


/***************** NEW USER MAIN ***********/
.new-user-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    background-color: #21B79B;
}

.new-user-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 60;
}

.get-name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 418px;
}

.get-user-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    min-width: 460px;
    margin-bottom: 10px;
}

.new-user-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 12vh;
}

.sign-up-button{
    width: 442px;
    height: 66px;
    border: none;

font-family: $josefin;
font-style: normal;
font-weight: 900;
font-size: 25px;
line-height: 29px;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #FFFFFF;
}
.sign-up-button:hover{
    border-radius:none;
}
.login-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 12vh;
}

.radio-text{
    font-family: $josefin;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
align-items: center;
letter-spacing: 0.03em;

color: #F0F0F0;
margin-left: 12px;
}


.student-checkbox-type, .teacher-checkbox-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

input[type="radio"]{
    width: 23px;
height: 23px;

background: #C4C4C4;
}

.signup-classcode{
    height: 65px;
    margin-top: 30px;
}


.login-button{
    margin-top: 20px;
}
.login-button:hover{
    margin-top: 40px;
}

/* Loading spinner */
@at-root.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@-webkit-keyframes lds-circle {
  0%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
            animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
            animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
@keyframes lds-circle {
  0%, 100% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
            animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
            animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}


.error{
    width: 100%;
    text-align: center;
    color: rgb(204, 70, 70);
    font-size: 1.2em;
}

/* Lesson Complete */
.lesson-complete{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(./components/svg/confetti.svg);
}

.lesson-complete-bold{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 35px;
line-height: 35px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #404040;
}

.lesson-complete-regular{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 35px;
line-height: 35px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;

color: #404040;
}

.lesson-complete-wrapper{
    background: #015587;
}

.lesson-complete-body{
    width: 80vw;
    height: 70vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    max-width: 1202px;
    max-height: 694px;
    background: #FFFFFF;
    border-radius: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.lesson-complete-circle{
    width: 305px;
    height: 305px;  
    background: #FFC300;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lesson-complete-fire{
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.lesson-complete-red{
    position: absolute;
    width: 127px;
    height: 164.24px;
}

.lesson-complete-yellow{
    position: absolute;
    width: 73.38px;
    height: 94.9px;
}

.lesson-complete-message{
    color: #D33131;
    font-size: 3em;
}

.lesson-complete-bottom-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2em;
}

.bold{
    font-weight: bold;
    font-size: 1.1em;
}

.boldface{
    font-weight: bold;
}

.lesson-complete-buttons{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.lesson-complete-try-holder{
    width: 311px;
    height: 71px;
    
    background: #71BA09;
    border-radius: 24px;
}

.lesson-complete-try-holder:hover{
    cursor: pointer;
}

.lesson-complete-try{
    width: 301px;
    height: 60px;

    background: #8EE000;
    border-radius: 19px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: white;
}

.lesson-complete-try:hover{
    cursor: pointer;
}
.lesson-complete-exit-holder{
    width: 213px;
    height: 71px;
    
    background: #D33131;
    border-radius: 24px;
}

.lesson-complete-exit-holder:hover{
    cursor: pointer;
}

.lesson-complete-exit-holder-fixed{
    position: absolute;
    left: 25px;
    top: 10px;
    width: 213px;
    height: 71px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
}

.lesson-complete-exit-holder-fixed:hover{
    cursor: pointer;
}

.quit-text{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    margin-left: 15px;
    color: #D33131;
    
}
.lesson-complete-exit{
    width: 206px;
    height: 60px;

    background: #FF5B5B;
    border-radius: 19px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: white;
}

.infinity{
    width: 100vw;
    min-height: 100vh;
    background: #BABABA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.infinity-what-skills{
    font-family: Josefin Sans;
font-style: normal;
font-weight: 600;
font-size: 23px;
line-height: 23px;
text-align: center;
letter-spacing: 0.03em;

color: #000000;
margin-bottom: 15px;
}



.infinity-body{
    background-color: #FFFFFF;
    width: 92.84vw;
    min-height: 80vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 40px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 3.41vh;
    margin-bottom: 3.41vh;
    
}

.infinity-rules-flex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 30%;
}

.infinity-title{
    margin-left: 3vw;
    margin-top: 5vh;
    margin-bottom: 22px;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 60px;
    display: flex;
    align-items: flex-start;
    letter-spacing: 0.03em;
    margin-left: 59px;
    color: #D33131;
}

.infinity-activity-type-word{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 23px;
line-height: 23px;
letter-spacing: 0.03em;

color: #494848;
}

.infinity-activity-type{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 14px;
}

.infinity-rules{
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.03em;
    height: 80%;
    color: #000000;
    width: 70%;
    margin-left: 59px;
}

.infinity-details{
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.03em;
    height: 80%;
    color: #000000;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.container-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    border-radius: 5px;
  }
  
  /* Create a custom checkbox */
  .checkmark-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    border-radius: 5px;
    width: 25px;
    background-color: #C5C5C5;
  }
  
  /* On mouse-over, add a grey background color */
  .container-checkbox:hover input ~ .checkmark-checkbox {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-checkbox input:checked ~ .checkmark-checkbox {
    background: #1CB100;

  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark-checkbox:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container-checkbox input:checked ~ .checkmark-checkbox:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container-checkbox .checkmark-checkbox:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

.rocket{
    position: absolute;
    height: 544.25px;
    left: 70%;
    top: 90px;
    transform: rotate(2deg);
}

.inf-play-holder{
    width: 251px;
    height: 58px;
    border-radius: 15px;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 12vh;
    padding-bottom: 44px;
    padding-right: 44px;
    position: absolute;
    right: 3.45vw;
    bottom: 0vh;
}

.inf-clef-selection-flex{
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 50%;
}

.inf-clef-text{
    text-align: center;
}

.inf-play{
    width: 245px;
    height: 44px;
    font-family: Josefin Sans;
    background: #8ED416;
    border-radius: 12px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.5em;
}

.inf-play:hover{
    cursor: pointer;
}

/* Warbler community page */
.community-holder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #BABABA;
    min-height: 90vh;
}

.community-body{
    background: #FFFFFF;
    border-radius: 40px;
    width: 80vw;
    min-height: 70vh;
    margin: 5vh 0px;
}

.community-lessons{
    min-height: 60vh;
    width: 90%;
    margin: 5vh 5% 3vh 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.community-lesson{
    min-width: 274px;
    width: 22vw;
    height: 20vh;
    background: #FFFFFF;
    border: 2px solid #AFAFAF;
    box-sizing: border-box;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.07), -4px -4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 25px;
    margin-bottom: 2vh;
    margin-right: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.community-lesson-icons{
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
}

#community-lesson-icon:hover{
    cursor: pointer;
}

.community-lesson-title{
    width: 80%;
    text-align: left; 
    font-size: 1.3em;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.03em;

    color: #373737;
    margin-bottom: 10px;
}

.community-lesson-subtitle{
    width: 80%;
    text-align: left;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.1em;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;

    color: #777777;
    margin-bottom: 4px;
}

.community-modal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.community-modal-body{
    background: #FFFFFF;
    border: 2px solid #373737;
    box-sizing: border-box;
    border-radius: 23px;
    height: 75vh;
    width: 61.38vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.community-modal-title{
    width: 100%;
    text-align: center;
    font-size: 2.5em;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: bold;
    line-height: 30px;
    /* identical to box height */
    
    text-align: center;
    letter-spacing: 0.03em;
    
    color: #373737;
    margin-bottom: 10px;
}

.community-modal-bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.community-modal-stat-title{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 1em;
line-height: 22px;

text-align: center;
letter-spacing: 0.03em;

color: #373737;
height: 66px;
display: flex;
flex-direction: column;
justify-content: center;
}

.community-modal-x-out{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.community-modal-x-out-image{
    margin-right: 16px;
    margin-top: 12px;
}

.community-modal-x-out-image:hover{
    cursor: pointer;
}

.community-modal-stat-number{
    font-size: 5em;
    margin-top: 67px;
    font-weight: bold;
    font-family: Josefin Sans;
font-style: normal;
line-height: 110px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
}
.stat-number-purple{
    color: #8549BA;
}
.stat-number-green{
    color: #7AC70C;
}
.stat-number-red{
    color: #D33131;
}

.community-modal-stat{
    background: #DDDDDD;
    border-radius: 17px;
    width: 238px;
    height: 262px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    font-family: Josefin Sans;
    font-style: normal;
    font-weight: bold;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.03em;

    color: #373737;
}

.community-modal-buttons{
    height: 15vh;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.community-modal-button-new{
    width: 250px;
    border: none;
height: 57px;
font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 23px;
text-align: center;
letter-spacing: 0.03em;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
color: #FFFFFF;

border-radius: 15px;
}

.cm-lightblue{
    background: #1CB0F6;
}
.cm-darkblue{
    background: #015587;
}

.cm-red{
    background: #D33131;
}

.community-adding-text{
    width: 60vw;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.03em;

    color: #373737;
}

.community-modal-adding{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    height: 80%;
    margin-top: 2vh;
}

.community-dropdown{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6em;
    line-height: 27px;
    
    letter-spacing: 0.03em;
    color: #FFFFFF;

    border-radius: 21px;
    background-color: #77D0FA;
}

.community-dropdown-selected{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1vw;

    width: 351px;
    height: 65px;
    font-size: 1.5em;
    background: #1CB0F6;
    border-radius: 21px;
}

.community-dropdown-selected:hover{
    cursor: pointer;
}

.community-dropdown-el{
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    padding-bottom: 0vh;
    width: 351px;
    height: 65px;
    font-size: 1.5em;
    background:  #77D0FA;
    border-bottom: 1px solid black;
    border-radius: 0px 0px 21px 21px;
}

.community-dropdown-el:hover{
    cursor: pointer;
    background:  #98ddfd;
}

.community-dropdown-bottom{
    max-height: 30vh;
    overflow-y: scroll;
}

/*
TEACHER LEADERBOARD OF STUDENTS
*/

.teacher-leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    padding-bottom: 5vh;
}

.selected-page-container-leaderboard {
    position: relative;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2), -4px -4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    width: 753px;
    margin-left: 10vw;
    margin-right: 50vw;
    font-family: Roboto;
    font-style: normal;
    letter-spacing: 0.03em;
}

.student-in-leaderboard {
    color: #373737;
    width: 100%;
}

.teacher-leaderboard-headers {
    display: flex;
    width: 100%;
}

.teacher-leaderboard-header-students {
    position: relative;
    text-align: center;
    line-height: 55px;
    width: 289px;
    height: 49px;
    margin-left: 55px;
    margin-top: 47px;
    margin-bottom: 17px;
    background-color: #2ba0cc;
    color: #FFFFFF;
    border-radius: 5px;
}

.teacher-leaderboard-header-xp {
    position: relative;
    text-align: center;
    line-height: 55px;
    width: 238px;
    height: 49px;
    border-radius: 4px;
    background-color: #FFC300;
    margin-top: 47px;
    margin-left: 113px;
    margin-bottom: 17px;
    color: #FFFFFF;
}

.student-in-leaderboard {
    display: flex;
    padding-top: 17px;
    padding-bottom: 17px;
    flex-direction: row;
    justify-content: flex-start;
}

.teacher-leaderboard-student-rank {
    margin-left: 15px;
    text-align: center;
    width: 20px;
}

.teacher-leaderboard-student-username {
    width: 289px;
    margin-left: 15px;
    text-align: center;
}

.teacher-leaderboard-student-xp {
    margin-left: 113px;
    width: 238px;
    text-align: center;

}
.cheat-sheet-title {
    font-family: $josefin;
    font-size: 50px;
    color: $lightblue;
    font-weight: 700;
}

.cheat-sheet {
    border: 1px solid #373737 !important;
    padding: 30px;
}

.cheat-sheet-toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    color: #afafaf;
    margin-top: 30px;
}

.cheat-sheet-toggle-container div {
    margin: 0 10px;
}

.cheat-sheet-toggle-container div:hover{
    cursor: pointer;
}

.cheat-sheet-toggle-container span {
    padding-right: 4px;
    outline: none;
}

.cheat-sheet-toggle-container .selected {
    color: $lightblue;
    text-decoration: underline;
}

.cheat-sheet-subtitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-family: $josefin;
    color: #404040;
    font-size: 24px;
    font-weight: 700;
    margin-top: 30px;
}

.cheat-sheet-yellow-highlight {
    background-color: $yellowtransparent;
    border-radius: 7px;
    padding: 0 5px 0 5px;
    margin: 0;
    text-align: center;
    font-weight: 700;
    color: #535353
}
.cheat-sheet-purple-highlight {
    background-color: $purpletransparent;
    border-radius: 7px;
    padding: 0 5px 0 5px;
    margin: 0;
    text-align: center;
    font-weight: 700;
    color: #535353
}
.cheat-sheet-blue-highlight {
    background-color: $bluehighlight;
    border-radius: 7px;
    padding: 0 5px 0 5px;
    margin: 0;
    text-align: center;
    font-weight: 700;
    color: #535353
}

.cheat-sheet-top-row-d {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-family: $josefin;
    color: #404040;
    font-weight: 400;
    margin-top: 20px;
    .cheat-sheet-top-item-d {
        flex-direction: column;
        justify-self: center;
        align-content: center;
        text-align: center;
        height: 136px;
        .note-abrev {
            background-color: $yellowtransparent;
            border-radius: 7px;
            padding: 0 5px 0 5px;
            margin: 0;
            text-align: center;
            font-weight: 700;
        }
        img {
            height: 100px;
        }
        #whole-note {
            height: auto;
            margin-top: 58px;
        }
        #double-whole-note {
            height: 34px;
            margin-top: 67px;
        }
    }
}

.cheat-sheet-bottom-row-d {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: $josefin;
    color: #404040;
    font-weight: 400;
    margin-top: 50px;
    .note-abrev-d {
        background-color: $yellowtransparent;
        border-radius: 7px;
        padding: 0 3px 0 3px;
        margin: 0;
        font-weight: 700;
    }
    .cheat-sheet-bottom-item-d {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        img {
            height: 100px;
        }
    }
    .cheat-sheet-dotted-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        img {
            height: 100px;
        }
    }

    .cheat-sheet-dotted-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.cheat-sheet-top-row-p {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    font-family: $josefin;
    color: #404040;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 20px;
    .cheat-sheet-top-item-p {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .cheat-sheet-top-item-title {
            margin-bottom: 30px;
            font-weight: 700;
            font-size: 24px;
            color: #535353;
        }
        .cheat-sheet-valid-notes {
            font-weight: 700;
            font-size: 24px;
            color: #535353;
            letter-spacing: 0.03em;
            margin-top: 10px;
        }
    }
    .cheat-sheet-modifiers-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px 0 10px;
        letter-spacing: 0.03em;
        font-size: 24px;
        font-weight: 700;
        color: #535353;
        .cheat-sheet-modifiers-text{
            font-size: 20px;
            font-weight: 300;
        }
    }

    .cheat-sheet-modifiers-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
}

.cheat-sheet-bottom-row-p {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-family: $josefin;
    color: #404040;
    font-weight: 400;
    .note-abrev-p {
        background-color: $yellowtransparent;
        border-radius: 7px;
        padding: 0 3px 0 3px;
        margin: 0;
        font-weight: 700;
    }
    .cheat-sheet-bottom-item-p {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .cheat-sheet-bottom-box-title {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 24px;
            color: #535353;
        }
    }
    #green-box {
        background-color: $palegreen;
        padding: 50px 100px;
        border-radius: 29px;
    }

    #red-box {
        background-color: $palered;
        padding: 50px 100px;
        border-radius: 29px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
  
/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}
  
input:checked + .slider {
    background-color: #1CB0F6;
}
  
input:focus + .slider {
    box-shadow: 0 0 1px #1CB0F6;
}
  
input:checked + .slider:before {
    transform: translateX(26px);
}
  
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}

.none-available{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6em;
    line-height: 27px;
}

.adding-success {
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6em;
    line-height: 27px;
}

/* About us Page */

.meet-you {
    margin-top: 60px;
    width: 804px;
    margin-left: 80px;
}

.about-us-intro {
    font-family: $josefin;
    font-style: normal;
    font-size: 30px;
    line-height: 30px;
    color: #000000;
    /* width: 978px;
    height: 253px; */
    margin-left: 390px;
    margin-right: 80px;
}

.get-to-know-us {
    /* width: 1440px;
    height: 160px;
    top: 696px; */
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #1273A3;
    margin-top: 80px;
}

.who-we-are {
    /* position: absolute;
    top: 877px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    .image {
        img {
            margin-right: 25px;
            border-radius: 34px;
            height: 280px;
            width: 280px;
        }
    }
}

.tile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 590px;
    height: 280px;
    margin: 40px 10px;
}

.image {
    margin-right: 25px;
    border-radius: 34px;
    height: 280px;
    width: 280px;
}

.name-tag {
    background: #95D23D;
    border-radius: 7px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 15px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.name-text {
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 27px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.location {
    margin-top: 5px;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: #E53B3B;
    margin-bottom: 5px;
}

.short-desc {
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #404040;
    margin-bottom: 10px;
}

.hobbies {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.hobby-text {
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.hobby-yellow-tag {
    background: #FFC300;
    border-radius: 5px;
    width: 114px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 3px;
    margin-right: 8px;
}

.hobby-red-tag {
    background: #FF9393;
    border-radius: 5px;
    width: 136px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 3px;
    margin-right: 8px;
}

.hobby-purple-tag {
    background: #B780EF;
    border-radius: 5px;
    width: 220px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 3px;
    margin-right: 8px;
}

.about-us-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background: $orangeGold;
    height: 29.66vh;
    /* position: absolute;
    bottom: 0;
    left: 0; */
}

.about-us-page{
    display: flex;
    flex-direction: column;
}

.about-us-footer-text {
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.about-us-wrapper {
    position: relative;
}

.teacher-lesson-navigation{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: $josefin;
    font-size: 1.8em;
    padding-top: 25px;
}

.cog{
    width: 30px !important;
    height: 30px !important;
    color: gray !important;
}

#tln-arrow{
    color: #1273A3;
}

#tln-arrow:hover{
    cursor: pointer;
}

.beta-error {
    width: 100%;
    text-align: center;
    color: #cc4646;
    font-size: 1.2em;
    margin-top: 15px;
}

.class-view-students-icon-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 20vh;
    overflow-y: scroll;
}

// New Nav Bar

.new-nav-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1;
    height: 68px;
    font-family: $oleo;
    background: $limeGreen;
    color: #FFFFFF;
}

.new-nav-bar-items-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    height: 68px;
}

.new-nav-bar-logo {
    width: 200px;
    height: 68px;
    font-size: 38pt;
    line-height: 68px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    left: 130px;
}

.new-nav-bar-items {
    display: flex;
    flex-direction: row;
    margin-right: 30px;
    font-family: $josefin;
    font-size: 15pt;
}

.new-nav-bar-left {
    display: flex;
    align-items: center;
}

.new-nav-bar-left .nav-back-button {
    color: #FFFFFF;
    font-family: $josefin;
    font-size: 17pt;
    position: absolute;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    left: 10px;
    width: 80px;
    text-align: center;
}

.new-nav-bar-left .nav-back-button i {
    padding-right: 5px;
}

.nav-bar-item {
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 10px;
    margin-right: 10px;
    height: 68px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.nav-bar-item i.fa-user {
    padding-left: 5px;
}

.nav-bar-item-text{
    font-family: $josefin;
    font-size: 25px;
}

.nav-bar-item-no-hover{
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 10px;
    margin-right: 10px;
    height: 68px;
    color: #FFFFFF;
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.nav-bar-item-row {
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 10px;
    margin-right: 10px;
    height: 68px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.nav-bar-item-row-no-hover {
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 10px;
    margin-right: 10px;
    height: 68px;
    color: #FFFFFF;
    cursor: default;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.nav-bar-item-woin {
    margin-bottom: 5px;
    margin-left: 8px;
}

.nav-bar-item-no-hover i.fa-user{
    padding-left: 5px;
}

.nav-bar-item:hover, .new-nav-bar-logo:hover, .nav-bar-item-row:hover {
    background: $correctBar;
    transition: 0.25s;
}

.nav-bar-title {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    line-height: 68px;
    text-align: center;
    font-size: 27pt;
    font-family: $josefin;
    cursor: default;
}

.nav-dropdown-items-container {
    position: relative;
    height: 1px;
    opacity: 0;
}

.nav-dropdown-open {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-bottom-left-radius: 12%;
    border-bottom-right-radius: 12%;
    transition: .25s;
    opacity: 1;
    height: 120px;
}

.nav-dropdown-item {
    background: white;
    color: $limeGreen;
    text-decoration: none;
    width: 100%;
    height: 40px;
    border: 1px solid $blue;
    align-items: center;
    justify-content: center;
    display: flex;
}

.nav-dropdown-item:hover {
    background: $correctBar;
}

a, a:visited, a:hover, a:active {
    text-decoration: none;
    color: inherit;
}
  
.tooltip-new {
position: relative;
}

/* Tooltip box */
.tooltip-new:before {
content: attr(data-tooltip); 
position: absolute;
width: 100px;
background-color: #062B45;
color: #fff;
text-align: center;
padding: 10px;
line-height: 1.2;
border-radius: 6px;
z-index: 1;
opacity: 0;
transition: opacity .6s;
bottom: 105%;
left: 50%;
margin-left: -60px;
font-size: 0.75em;
visibility: hidden;
}

/* Tooltip arrow */
.tooltip-new:after {
content: "";
position: absolute;
bottom: 75%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
opacity: 0;
transition: opacity .6s;
border-color: #062B45 transparent transparent transparent;
visibility: hidden;
}

.tooltip-new:hover:before, .tooltip-new:hover:after {
opacity: 1;
visibility: visible;
}
.modal-flexbox-code{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.activity-type{
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    justify-self: flex-start;
    margin-bottom: 10px;
    font-size: 10px;
    font-family: $josefin;
}


.correct-anim{
    width: 100vw;
    height: 100vh;
    position: absolute;
}
.incorrect-anim{
        width: 20vw;
        left: 50%;
        margin-left: -10vw;
        height: 30vh;
        position: absolute;
}



/******** in class game tile **********/
#in-class-game-view {
    background-color: $newDarkBlue;
}

/**************** In class game styling ***********/
/********* Teacher start game *********/
.ic-game-flex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
}

.ic-game-flex-col-teacher{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    height: 100%;
}


.ic-left-half{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-bottom: 20px;
    height: 80%;
}

.ic-left-lower-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 40px;
}

#ic-teacher-title{
    color: $incorrectRed;
    font-family: $josefin;
    font-style: italic;
    font-weight: bold;
    font-size: 2.7em;
}

.ic-teacher-text{
    font-size: 1.3em;
}

.j{
    font-family: $josefin;
}

.ic-teacher-text-section{
    display: flex;
    flex-direction: column;
    width: 65%;
}

#ic-code-title{
    color: black;
    font-size: 1.4em;
}



.ic-left-lower-left-section{
    width: 433px;
    height: 18em;
    background-color: $gray;
    border-radius: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#ic-code-holder{
    background-color: white;
    width: 319.66px;
    height: 74px;
    color: $newDarkBlue;
    font-weight: bold;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#blue-button-new-width{
    width: 350px;
    height: 40px;
    font-size: 27px;
    margin: 0px;
    margin-bottom: 20px;
    border-radius: 12px;
    transition: 100ms ease all;
}

.ic-left-lower-right-section{
    background: #DFDFDF;
    border-radius: 23px;  
    width: 18em;
    height: 14em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#ic-players-playing{
    color: $newDarkBlue;
    font-weight: bold;
    font-size: 8em;
    font-family: $josefin;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

#ic-players-playing-subtitle{
    font-weight: bold;
    font-size: 1.25em;
    text-align: center;
    color: $newDarkBlue;
}

.ic-right-section{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    height: 100%;
    padding-right: 20px;
}

.ic-right-upper{
    width: 452px;
    height: 565px;
    background-color: $gray;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.ic-right-upper-inner{
    background-color: white;
    height: 75%;
    width: 80%;
    overflow: scroll;
}

.green-button{
    background: $limeGreen;
    border-radius: 12px;
    color: white;
    box-shadow: 5px 9px 0px #365500;
    width: 301px;
    height: 59px;
    font-family: $josefin;
    font-weight: bold;
    font-size: 27px;
    outline: none;
    border: none;
    transition: 100ms ease all;
}
#ic-list-title{
    color: $newDarkBlue;
    font-size: 1.9em;
    font-weight: bold;
    font-family: $josefin;
}

/************ Student join game ********/
.ic-game-flex-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
}

.ic-game-flex-col-bottom{
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#ic-student-subtitle{
    margin: 0px;
    font-weight: normal;
    font-family: $josefin;
    font-size: 30px;
    margin-top: 35px;
    margin-bottom: 55px;
    text-align: center;
}

.ic-student-enter-code{
    width: 806px;
    height: 18em;
    background-color: $gray;
    border-radius: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#ic-student-enter-code-input{
    width: 65%;
    height: 40%;
    border: none;
    text-align: center;
    color: $newDarkBlue;
    font-weight: bold;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: $josefin;
}

#blue-button-new-width-student{
    width: 65%;
    height: 50px;
    font-size: 27px;
    margin: 0px;
    margin-bottom: 20px;
    border-radius: 12px;
}


#ic-code-title-student{
    color: black;
    font-size: 1.6em;
    margin-top: 20px;
}

#ic-teacher-title-student{
    color: $incorrectRed;
    font-family: $josefin;
    font-style: italic;
    font-weight: bold;
    font-size: 4em;
    text-align: center;
}

.in-class-vex-wrapper{
    width: 450px;
    height: 130px;
    background: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.mc-answer-button-in-class{
    height: 200px;
    width: 571px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    box-sizing: border-box;
    border-radius: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -4px -4px 10px rgba(0, 0, 0, 0.25);
    transition: 100ms ease all;
}

.in-class-answer-choice-num{
    font-family: $baloo;
    font-style: normal;
    font-weight: 800;
    font-size: 100px;
    line-height: 154px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

#red-answer-ic{
    background: $red;
}

#yellow-answer-ic{
    background: $gold;
}

#blue-answer-ic{
    background: $calmBlue;
}

#purple-answer-ic{
    background: $brightPurple;
}



.mc-options-in-class{
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: $newGray;
    width: 100%;
    height: 100%;
}

.ic-answer-button{
    width: 586px;
    height: 251px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: $baloo;
    font-style: normal;
    font-weight: 800;
    font-size: 250px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: white;
    border: none;
    transition: 100ms ease all;
}

.ic-answer-button:hover{
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -4px -4px 10px rgba(0, 0, 0, 0.25);
}

.color-red{
    background: $red;
    transition: 100ms ease all;
}

.color-gold{
    background: $gold;
    transition: 100ms ease all;
}

.color-calm-blue{
    background: $calmBlue;
    transition: 100ms ease all;
}

.color-purple{
    background: $brightPurple;
    transition: 100ms ease all;
}

.ic-student-answer-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.ic-main-screen{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ic-student-answer-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.selected-ic-choice{
    border-radius: 20px;
}

.nonselected-ic-choice{
    background: gray;
}

.green-button:hover{
    box-shadow: none;
}

.names-ic{
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 117%;
    /* or 32px */
    display: flex;
    align-items: center;
    justify-content: space-around;
    letter-spacing: 0.03em;

    color: $newDarkBlue;
    margin-bottom: 10px;
    margin-top: 10px;
}

.num-name-ic{
    width: 26%;
}

.names-ic-holder{
    display: flex;
    flex-direction: row;
}

.num-ic{
    justify-self: flex-end;
    padding-right: 5px;
}

.ic-names-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 10%;
    width: 100%;
    padding-left: 13%;
}

.ic-waiting-screen{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.ic-waiting-text{
    font-family: $josefin;
    font-style: italic;
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;

    color: $teal;
}

.result-background{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.green-background{
    background-color: $correctGreen;
    background-image: url('../src/components/published_components/InClassGame/assets/result_confetti.svg');
}

.confetti-background{
    background-image: url('../src/components/published_components/InClassGame/assets/result_confetti.svg');
}

.confetti-background-final{
    background-image: url('../src/components/published_components/InClassGame/assets/confetti_final.svg');
    background-size: cover;
}

.red-background{
    background-color: $incorrectRed;
}

.result-screen-title{
    font-family: $baloo;
    font-style: normal;
    font-weight: 800;
    font-size: 100px;
    line-height: 154px;
    /* identical to box height */
    margin: 0px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;

    color: white;
}

.total-score{
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 25px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.03em;

    color: white;
}

.result-inner-holder{
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.added-points-box{
    width: 339px;
    height: 72px;
    background-color: $correctGreenDarkened;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    margin-bottom: 30px;
    color: white;
}

.confetti-background{
    position: absolute;
    height: 100vh;
    width: 100vw;
}

.orange-bird{
    margin-right: 110px;
    margin-bottom: 40px;
}

.teacher-results-title{
    font-family: $baloo;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 61px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;

    color: black;
    background: $newGray;
    width: 100%;
    height: 18.4%;
    justify-content: center;
}


.bar-count{
    width: 561px;
}

.bigger-bar{
    height: 149px;
}

.smaller-bar{
    height: 68px;
}

.green-bar{
    background: $correctBar;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.red-bar{
    background: $incorrectBar;
}

.bar-correct-answer{
    height: 64px;
    width: 100%;
    background-color: $limeGreen;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.correct-answer-big-num{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    margin-right: 25px;
    color: white;
}

.bar-text{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.03em;
    margin-top: 19px;
}

.green-text-ic{
    color: $correctGreen;
}

.red-text-ic{
    color: $incorrectRed;
}

.bar-graphs{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    width: 100%;
}

.teacher-results-ic{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.teacher-results-ic-inner{
    height: 95%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.bar-count{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 60px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.main-bar-ic{
    width: 561px;
    margin-right: 33.5px;
    margin-left: 33.5px;
}

.ic-button-flex-results{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 45px;
    padding-bottom: 31px;
    width: 100%;
}

.results-lead-tracker{
    width: 991px;
    height: 90px;
    background: $lightGold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.results-lead-text{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: $orangeGold;
    margin-left: 20px;
}

.ribbon-class{
    height: 100%;
    width: 100%;
}

.answer-streak-ic{
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 37px;
    line-height: 37px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.03em;

    color: white;
}

.points-behind{
    font-family: $josefin;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 25px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;

color: white;
}

.current-place{
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 30px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.03em;

    color: #FFFFFF;
}

.result-background-student-final{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: #C8E596;
}

.result-final-title{
    font-family: $baloo;
    font-style: normal;
    font-weight: 800;
    font-size: 100px;
    line-height: 154px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: $correctGreen;
    margin: 0px;
}

.result-final-end-place{
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 37px;
    line-height: 37px;
    /* identical to box height */
    letter-spacing: 0.03em;
    text-align: center;
    color: black;
}

.result-final-points-box{
    width: 339px;
    height: 72px;
    background: $correctGreenDarkened;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: white;
    margin-bottom: 25px;
}

.result-final-ic-xp-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 50px;
    background: $correctGreenDarkened;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 25px;
}

.result-final-ic-coins-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 50px;
    background: $correctGreenDarkened;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    text-align: center;
}

.result-final-ic-woin-image {
    margin-left: 5px;
}

.red-button{
    background: $incorrectRed;
    border-radius: 19px;
    width: 296px;
    height: 49px;
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: white;
    box-shadow: 6px 9px 0px $incorrectRedDarkened;
    transition: 100ms ease all;
    outline: none;
    border: none;
}

#return-to-lobby-student{
    margin-top: 86px;
}

.red-button:hover{
    box-shadow: none;
}

.final-results-teacher-title{
    font-family: $baloo;
    font-style: normal;
    font-weight: 800;
    font-size: 7vw;
    line-height: 154px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    margin: 0px;
    color: black;
}

.final-result-bar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 450px;
    
}

.orange-bar{
    color: $orangeGold;
}

.seafoam-bar{
    color: $teal;
}

.sky-blue-bar{
    color: $skyblue;
}

.placeholder-bar{
    color: transparent;
}

.orange-bar-size{
    background: $orangeGold;
    width: 162px;
    height: 333px;
}

.seafoam-bar-size{
    width: 162px;
    height: 230px;
    background: $teal;
}

.sky-blue-bar-size{
    background: $skyblue;
    width: 162px;
    height: 115px;
}

.placeholder-bar-size{
    background: transparent;
    width: 162px;
    height: 115px;
}

.final-result-bar-num{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 100px;
    text-align: center;
    line-height: 100px;
}

.final-result-bar-user{
    margin-top: 20px;
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 37px;
    line-height: 37px;
    justify-content: center;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;

    color: black;
}

.bar-section-final{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 60%;
}

.bold-name{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 37px;
line-height: 37px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.03em;

color: #000000;
}

.final-results-button-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    margin-top: 33px;
}

.countdown-anim{
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.seconds-text-nav{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
}

.answered-text-nav{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 1.2em;
line-height: 25px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
}

.answered-text-nav-num{
    font-family: $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 1.75em;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
}

.flex-col-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#ic-listening-mc-main{
    overflow: hidden;
    height: 100vh;
}

#answer-streak-ic-space{
    margin-right: 4px;
}

.difficulty-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 75%;
}

.difficulty-button{
    width: 90px;
    height: 53px;
    border-radius: 4px;
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.03em;
    border: transparent;
    color: #FFFFFF;
    transition: 100ms ease all;
}

.difficulty-button:active{
    padding-top: 7px;
    padding-left: 7px;
}

.difficulty-button:hover{
    opacity: .75;
}

$box-shadow-green: #365500;
$box-shadow-yellow: #C46A18;
$box-shadow-red:  #800303;

.green-dif{
    background: $correctGreen;
    box-shadow: 4px 5px 0px $box-shadow-green;
}

.button:active{
    padding-top: 5px;
    padding-left: 5px;
}

.yellow-dif{
    background: $yellow;
    box-shadow: 4px 5px 0px $box-shadow-yellow;
}

.red-dif{
    background: $red;
    box-shadow: 4px 5px 0px $box-shadow-red;
}

.active-difficulty{
    box-shadow: none;
}

.rhythm-spacebar-ic{
    width: 70vw;
    height: 12vh;
    background: #FF9400;
    box-shadow: 4px 4px 10px #FFC300, -4px -4px 10px #FFC300;
    border-radius: 25px;
    margin-top: 42px;
}

.rhythm-ic-instructions{
    font-family:  $josefin;
    font-style: normal;
    font-weight: bold;
    font-size: 3em;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;

    color: #000000;
}

.ic-student-answer-rest{
    height: 100%;
}

/******* mobile sizing for in-class game *****/
@media only screen and (max-width: 900px){
    #ic-teacher-title-student{
        font-size: 2em;
    }
    #ic-student-subtitle{
        font-size: 1.5em;
    }
    .ic-student-enter-code{
        width: 75vw;
    }
    #ic-student-enter-code-input{
        width: 55vw;
        height: 10vh;
        font-size: 2em;
    }
    #blue-button-new-width-student{
        font-size: 1em;
        width: 55vw;
        height: 4vh;
    }
    #hey-name{
        font-size: 2.5em;
        overflow-wrap: anywhere;
    }
    .ic-student-answer-flex{
        flex-direction: column;
        height: 100vh;
        width: 100vw;
    }
    .ic-student-answer-row{
        flex-direction: row;
    }
    .ic-answer-button{
        width: 45vw;
        height: 38vh;
        font-size: 5em;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .ic-student-answer-bottom{
        margin-bottom: 40px;
    }
    .ic-waiting-screen{
        justify-content: center;
        height: 100%;
    }
    .ic-waiting-text{
        font-size: 3em;
        padding: 15px;
    }

    .bar-section-final{
        width: 90%;
    }

    .result-screen-title{
        font-size: 5em;
    }
    .answer-streak-ic, .current-place{
        font-size: 1.3em;
        text-align: center;
    }
    .points-behind{
        font-size: 1.2em;
    }
    .logo-bird-waiting{
        width: 100%;
    }
    .full-screen{
        max-width: 100vw;
        max-height: 100vh;
    }

    .bar-section-final{
        flex-direction: column;
        height: 60%;
    }

    .orange-bar-size{
        width: 175px;
        height: 80px;
    }
    .seafoam-bar-size{
        width: 125px;
        height: 80px;
    }

    .sky-blue-bar-size{
        width: 100px;
        height: 80px;
    }

    .orange-bar-size, .seafoam-bar-size, .sky-blue-bar-size{
        margin-right: 25px;
    }

    .final-result-bar-mobile{
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        display: flex;
    }

    .final-result-bar-user{
        font-size: 1.2em;
        margin-top: 0px;
    }

    .mobile-num{
        width: 25%;
        text-align: center;
    }
    .confetti-background-final{
        background-image: linear-gradient(rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.3)), url(    '../src/components/published_components/InClassGame/assets/confetti_final.svg');
        background-size: cover;
    }

    #render-details-button{
        font-size: 1.2em;
        width: 45%;
    }

    #return-to-lobby-teacher{
        font-size: 1.2em;
        width: 100%;
    }
    .final-results-button-section{
        width: 100%;
    }

    .final-results-teacher-title{
        font-size: 3em;
    }

    .ic-main-screen{
        display: block;
    }
}

/******* Store *****/
#store-main{
    border-radius: 19px;
    width: 39.4vw;
    height: 88vh;
    background: $StorePeach;
    margin-top: 3vh;
}

#store-upper, #store-lower{
    width: 100%;
}

#store-upper{
    position: relative;
    height: 55%;
}

#bird-profile-image{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transform: scale(.8);
    z-index: 2;
}

#store-profile-bird-outer{
    width: 39.4vw;
}
.profile-bird-image-holder{
    position: absolute;
}

#store-upper-middle{
    height: 80%;
    width: 100%;
}

#store-middle{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
    height: 70%;
    
}

#store-middle::-webkit-scrollbar{
    background: transparent;
}

#store-middle::-webkit-scrollbar-thumb{
    background: $StorePeach;
    border-radius: 10px;
    height: 20px;

}

#store-upper-upper{
    display: flex;
    position: absolute;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    right: 0;
}

#store-upper-middle{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding-top: 20px;
}

.bird-profile-hat{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: -2px;
    z-index: 3;
    transform: scale(.8);
}

.bird-profile-platform{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    z-index: 0;
    transform: scale(.8);
}

.bird-profile-accessory{
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: 20%;
    transform: scale(.9);
}

#store-upper-lower{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 20px;
    position: relative;
    z-index: 5;
}

#store-lower{
    background: $StoreLowerBackground;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
    height: 45%;
}

#store-coins-background{
    width: 100px;
    height: 50px;
    border-radius: 32px;
    background: $StoreLowerBackground;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 10px;
    margin-top: 10px;
}

.store-color-swatches{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 75%;
    height: 20%;
}

.store-color-swatch{
    width: 20px;
    height: 20px;
    border-radius: 100px;
    border: 2px black solid;
    transition: 150ms ease all;
}

.active-swatch{
    border: 4px black solid;
}

.store-color-swatch:hover{
    cursor: pointer;
    border: 2px gray solid;
}

#store-color-swatch-red{
    background-color: red;
}

#store-color-swatch-orange{
    background-color: orange;
}

#store-color-swatch-yellow{
     background-color: yellow;
}

#store-color-swatch-green{
    background-color: green;
}

#store-color-swatch-blue{
    background-color: blue;
}

#store-color-swatch-purple{
    background-color: purple;
}

#store-color-swatch-pink{
    background-color: pink;
}

#store-coin-amount{
    font-family: $josefin;
font-style: normal;
font-weight: bold;
font-size: 1.3em;
line-height: 0%;
/* or 41px */

display: flex;
align-items: center;

color: white;
}

.store-text{
    font-family:  $josefin;
font-style: normal;
font-size: 1.2em;
line-height: 20px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
color: white;
padding: 4px;
}

.active-store-page{
    font-weight: bold;
}

.store-text-outer{
    padding: 10px;
}

#store-lower-upper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 15%;
}
#store-lower-middle{
    height: 85%;
}

.store-item-background{
    background: $StorePeach;
    border-radius: 10px;
    width: 30%;
    margin-left: 8px;
    margin-right: 6px;
    margin-top: 12px;
    margin-bottom: 12px;
    position: relative;
}

.store-item-background:after{
    padding-bottom:  100%;
    display: flex;
    content: "";
}

.store-item-button{
    font-family: $josefin;
color: white;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 12px;
text-align: center;
letter-spacing: 0.03em;
width: 40%;
align-self: center;
text-align: center;
border-radius: 5px;
outline: transparent;
border: transparent;
margin-top: 10px;
height: 15%;
}

.item-locked{
    background: $StoreLockedRed;
}

.item-unlocked{
    background: $StoreUnlockedGreen;
}

.store-item-inner{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
}

.store-item-image{
    padding: 0px;
    height: 75%;
}

.store-item-coins{
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    top: 0;
    right: 0;
    background-color: $StoreLowerBackground;
    width: 44px;
    border-radius: 5px;
    height: 24px;
    margin-right: 5px;
    margin-top: 3.5px;
}

.store-item-warbler-coin{
    transform: scale(1.2);
}

.store-item-coin-amount{
    color: white;
    font-family: $josefin;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
}

#bird-profile-hat-bow{
    top: 20px;
}

#bird-profile-hat-frog, #bird-profile-hat-{
    top: -2px;
}

#bird-profile-hat-beanie{
    top: 7px;
}
